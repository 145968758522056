import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/auth-context";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./index.css";

// Add Firebase notification handling code here
function initializeFirebaseMessaging() {
  if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
    // console.error('Firebase messaging requires HTTPS. Current protocol:', window.location.protocol);
    return; // Optionally exit the function if not using HTTPS
  }
  // Function to handle new notifications
  function handleNewNotification(notification) {
    // Get existing notifications from localStorage
    let notifications = JSON.parse(localStorage.getItem('notifications') || '[]');
    
    // Add the new notification
    notifications.push({
      id: Date.now(), // Unique ID based on timestamp
      title: notification.notification.title,
      body: notification.notification.body,
      timestamp: new Date().toISOString(),
      isRead: false,
      data: notification.data || {}
    });
    
    // Save back to localStorage
    localStorage.setItem('notifications', JSON.stringify(notifications));
    
    // Update notification count
    const unreadCount = notifications.filter(n => !n.isRead).length;
    localStorage.setItem('unreadNotificationsCount', unreadCount);
    
    // Dispatch an event so other parts of your app can react
    window.dispatchEvent(new CustomEvent('notificationsUpdated', {
      detail: { unreadCount, notifications }
    }));
    
    // console.log('New notification stored in localStorage', unreadCount);
  }

  // Listen for messages from the service worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', function(event) {
      // console.log('Received message from service worker:', event.data);
      
      if (event.data && event.data.type === 'NEW_NOTIFICATION') {
        handleNewNotification(event.data.payload);
      }
    });
  }

  // Function to register the service worker
async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      
      // Use a dynamic path based on your environment
      const swPath = window.location.pathname.includes('/app/') 
        ? '/app/firebase-messaging-sw.js' 
        : '/firebase-messaging-sw.js';
      
      const registration = await navigator.serviceWorker.register(swPath, {
        scope: '/'
      });
      
      console.log('Service Worker registered with scope:', registration.scope);
      
      // Add additional logging to help with debugging
      if (registration.active) {
        console.log('Service worker is already active');
      } else if (registration.installing) {
        console.log('Service worker is installing');
      } else if (registration.waiting) {
        console.log('Service worker is waiting');
      }
    } catch (error) {
      console.error('Service Worker registration failed:', error.message);
    }
  } else {
    console.error('Service workers not supported in this browser');
  }
}
  // Register the service worker
  registerServiceWorker();
}

// Add this function before initializing Firebase messaging
function checkBrowserSupport() {
  console.log('=== Browser Support Diagnostics ===');
  console.log('serviceWorker in navigator:', 'serviceWorker' in navigator);
  console.log('PushManager in window:', 'PushManager' in window);
  console.log('Notification in window:', 'Notification' in window);
  
  if ('Notification' in window) {
    console.log('Notification permission:', Notification.permission);
  }
  
//   console.log('Protocol (must be HTTPS except on localhost):', window.location.protocol);
//   console.log('Current path:', window.location.pathname);
}

// Call this before initializing Firebase messaging
checkBrowserSupport();

// Initialize Firebase messaging
initializeFirebaseMessaging();

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// Rebuilding Main Branch