// InfoUnit.jsx
import Container from "react-bootstrap/Container";
import Info from "./Info";

function InfoUnit() {
  return (
    <Container as="article" className="h-100 justify-content-between py-2" style={{ maxWidth: "90%" }}>  {/* Added padding and max-width */}
      <Info title="Admin Facilities" />
      <Info title="Manage Operations" />
      <Info title="Orders, Billing & Payments" />
      <Info title="Patient Records Management" />
      <Info title="Manage Appointments" />
    </Container>
  );
}

export default InfoUnit;