import { useEffect, useState } from "react";
import { Card, Container, Alert, Placeholder } from "react-bootstrap";
import PageContentLayout from "../components/page-content/PageContentLayout";
import { RX_OPD_ENDPOINTS } from "../utils/api/apiEndPoints";
import { rxOpdApi } from "../utils/api/api";

const NotificationSkeleton = () => {
  return (
    <Container className="mt-4">
      <Placeholder as="h3" animation="glow" className="mb-4">
        <Placeholder xs={4} />
      </Placeholder>

      <Alert variant="info" className="mb-4 rounded shadow-sm">
        📢 Push notifications are retained for <strong>30 days only.</strong>
      </Alert>

      {[1, 2, 3].map((item) => (
        <Card key={item} className="mb-3 p-3 border-0 rounded shadow-lg">
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> 
              <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as="small" animation="glow">
              <Placeholder xs={3} />
            </Placeholder>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

const Notificationbell = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      rxOpdApi.setUserSecretAuthHeaders();

      try {
        const res = await rxOpdApi.post(
          RX_OPD_ENDPOINTS.NOTIFICATION.NOTIFICATION_LIST
        );
        
        if (res) {
          setNotifications(res?.data?.notification_list || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  // Function to format date and time
  const formatDateTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);

    // Format Date: "8 Jan 2025"
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(dateObj);

    // Format Time: "07:15 PM"
    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(dateObj);

    return { formattedDate, formattedTime };
  };

  // Group notifications by formatted date
  const groupedNotifications = notifications.reduce((acc, notification) => {
    const { formattedDate, formattedTime } = formatDateTime(notification.sent_on);

    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push({ ...notification, formattedTime });
    return acc;
  }, {});

  // If loading, show skeleton loader
  if (isLoading) {
    return (
      <PageContentLayout>
        <NotificationSkeleton />
      </PageContentLayout>
    );
  }

  // If no notifications
  if (notifications.length === 0) {
    return (
      <PageContentLayout>
        <Container className="mt-4 text-center">
          <h3 className="fw-bold">Notifications</h3>
          <Alert variant="info" className="mb-4 rounded shadow-sm">
            📢 Push notifications are retained for <strong>30 days only.</strong>
          </Alert>
          <p className="text-muted">No notifications at the moment.</p>
        </Container>
      </PageContentLayout>
    );
  }

  return (
    <PageContentLayout>
      <Container className="mt-4">
        <h3 className="fw-bold">Notifications</h3>

        {/* Styled Alert Box for 30-day retention notice */}
        <Alert variant="info" className="mb-4 rounded shadow-sm">
          📢 Push notifications are retained for <strong>30 days only.</strong>
        </Alert>

        {Object.entries(groupedNotifications).map(([date, messages]) => (
          <div key={date} className="mt-3">
            <h5 className="text-primary fw-semibold">{date}</h5>

            {messages.map((notification, index) => (
              <Card key={index} className="mb-3 p-3 border-0 rounded shadow-lg">
                <Card.Body>
                  <Card.Title className="fw-bold">{notification.message_title}</Card.Title>
                  <Card.Text className="text-secondary">{notification.message_body}</Card.Text>
                  <small className="text-muted fw-bold">🕒 {notification.formattedTime}</small>
                </Card.Body>
              </Card>
            ))}
          </div>
        ))}
      </Container>
    </PageContentLayout>
  );
};

export default Notificationbell;