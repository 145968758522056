import { useState, useEffect, useContext } from "react";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  tableCellClasses,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material";
import Button from "../ui/Button";
import { RemoveCircle } from "@mui/icons-material";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Toast from "../ui/Toast";
import ModeContext from "../../context/mode-context";
import BookletModal from "../booklet/BookletModal";
import PrintOptionsModal from "../opd/PrintOptionsModal ";
import AppointModal from "../opd/AppointModal";
import Spinner from "react-bootstrap/Spinner";

const Casefile = ({
  fileId,
  setShowExtraTabs,
  setCurrentActive,
  caseid,
  reported,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const { currentHospital } = useContext(HospitalsContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [pdfDatas, setPdfDatas] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [newfile, setNewfile] = useState("");
  const [appointmentid, SetAppointmentid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAddFileSection, setShowAddFileSection] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // New state to store selected file
  const [fileType, setFileType] = useState("xray");
  const [errors, setErrors] = useState({
    file: "",
    fileType: "",
    description: "",
  });
  const [loadingAppointmentDetails, setLoadingAppointmentDetails] =
    useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [showFileTable, setShowFileTable] = useState(false);

  const [selectedFileCodes, setSelectedFileCodes] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [isPrintOptionsModalOpen, setIsPrintOptionsModalOpen] = useState(false);
    const [deletingFile, setDeletingFile] = useState(null);
    const [descriptionEdited, setDescriptionEdited] = useState(false);

  const { mode } = useContext(ModeContext);
  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];
  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionEdited(true);
  }

  const handleFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      if (!descriptionEdited) {
        setDescription(event.target.files[0].name);
      }
    }
  };
  
  const validateFields = () => {
    const newErrors = {
      file: !selectedFile ? "Please select a file" : "",
      fileType: !fileType ? "Please select a file type" : "",
      description: !description ? "Please enter a file name" : "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== "");
  };
  const fetchappointment = async () => {
    setLoadingAppointmentDetails(true);
    try {
      const res = await rxOpdApi.get(
        RX_OPD_ENDPOINTS?.HOSPITAL?.PATIENT?.PATIENT_CASE_FILE +
          "/" +
          currentHospital?.hos_id +
          "/" +
          fileId
      );
      if (res) {
        setLoadingAppointmentDetails(false);
        SetAppointmentid(res?.data?.appointment_id);
        setNewfile(res.data.records);
      }
    } catch (err) {
      setLoadingAppointmentDetails(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchappointment();
  }, []);

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveFile = async () => {
    if (validateFields()) {
      setLoadingAppointmentDetails(true);
    }

    const formData = new FormData();
    formData.append("patient_file", selectedFile);

    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      rxOpdApi.setAuthHeaders(key, secret);

      const encodedFileType = encodeURIComponent(fileType);
      const encodedFileName = encodeURIComponent(selectedFile.name);

      const response = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT?.ADD_FILE}${currentHospital.hos_id}/${appointmentid}/${fileId}/${encodedFileType}/${encodedFileName}`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setLoadingAppointmentDetails(false);

        await fetchappointment();
        setShowToast(true);
        setToastType("success");
        setToastMessage("File uploaded successfully");
        setShowAddFileSection(false);
        setShowFileTable(true);
      }
    } catch (error) {
      setShowToast(true);
      setLoadingAppointmentDetails(false);
      setToastType("error");
      setToastMessage(
        error.message || "Failed to upload file. Please try again."
      );
    }
  };

  const handleCloseModals = () => {
    setIsModalOpens(false);
  };

   const handleDeletePrompt = async (row, index) => {
      try {
        setDeletingFile(index);
  
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];
        rxOpdApi.setAuthHeaders(key, secret);
  
        const response = await rxOpdApi.delete(
          RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.DELETE_PATIENT_FILE +
            currentHospital.hos_id +
            "/" +
            fileId +
            "/" +
            row.file_code
        );
  
        if (response.data) {
          // Remove the deleted file from the newfile state
          await fetchappointment();
          setNewfile((prevFiles) => prevFiles.filter((_, i) => i !== index));
          setShowToast(true);
          setToastType("success");
          setToastMessage("File deleted successfully");
        } else {
          throw new Error(response.data.message || "Failed to delete file");
        }
      } catch (error) {
        console.error("Error deleting file:", error);
        setShowToast(true);
        setToastType("error");
        setToastMessage(
          error.message || "Failed to delete file. Please try again."
        );
      } finally {
        setDeletingFile(null);
      }
    };

  const handlePrintOptionSelected = async (printWithBooklet) => {
    setLoadingAppointmentDetails(true);
    setIsPrintOptionsModalOpen(false);

    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      const apiUrl = `${process.env.REACT_APP_RX_OPD}${RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.VIEWNGEN}/${currentHospital.hos_id}/${fileId}`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(key + ":" + secret),
        },
        body: JSON.stringify({
          file_code_list: selectedFileCodes,
          print_booklet: printWithBooklet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setPdfData(blobUrl);
      setLoadingAppointmentDetails(false);

      // Open the modal to display the PDF
      setIsModalOpen(true); // or setOpenModal(true), depending on which state variable you're using
    } catch (err) {
      setLoadingAppointmentDetails(false);

      console.error("Error in handleView:", err);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  };

  const AddFileSection = () => (
    <>
      <div onClick={() => setShowAddFileSection(false)} className="mt-3">
        <ArrowBackIosOutlined style={{ cursor: "pointer" }} />
      </div>
      <div className="mt-3">
        <span style={{ textAlign: "center" }}>
          Click on upload icon to browse & select file. Select type and edit
          name as per your choice.
        </span>
        <FormControl
          fullWidth
          margin="normal"
          style={{ border: "1px solid", textAlign: "center" }}
        >
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <label htmlFor="file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              fullWidth
              style={{ marginBottom: "16px" }}
            >
              Case Files
            </Button>
            <DriveFolderUploadIcon
              style={{ float: "inline-end", margin: "16px" }}
            />
          </label>
        </FormControl>
        {errors.file && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.file}
          </div>
        )}

        <FormControl fullWidth margin="normal">
          <InputLabel id="file-type-label">File Type</InputLabel>
          <Select
            labelId="file-type-label"
            id="file-type-select"
            value={fileType}
            label="File Type"
            onChange={handleFileTypeChange}
          >
            <MenuItem value="prescription">Prescription</MenuItem>
            <MenuItem value="labReport">Lab Report</MenuItem>
            <MenuItem value="xray">X-ray</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        {errors.fileType && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.fileType}
          </div>
        )}

        <TextField
          fullWidth
          margin="normal"
          id="file-description"
          label="File Name"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
        />
        {errors.description && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.description}
          </div>
        )}

        <Button onClick={handleSaveFile}>SAVE</Button>
      </div>
    </>
  );
  const handleback = () => {
    setShowExtraTabs(false);
    setCurrentActive(3);
  };

  const handlepost = async (row) => {
    setLoadingAppointmentDetails(true);

    const apiUrl =
      process.env.REACT_APP_RX_OPD +
      RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.VIEWPRE +
      "/" +
      currentHospital?.hos_id +
      "/" +
      fileId +
      "/" +
      row?.file_code;

    // Fetch the PDF data
    fetch(apiUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Basic " + btoa(key + ":" + secret),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Convert the PDF blob to a URL and set it in state
        const blobUrl = URL.createObjectURL(blob);
        setPdfDatas(blobUrl);

        // Open the modal to display the PDF
        setIsModalOpens(true);
        setLoadingAppointmentDetails(false);
      });
  };

  const handleView = async () => {
    const selectedFileCodes = selectedRows
      .map((index) => newfile[index]?.file_code)
      .filter(Boolean);
    setSelectedFileCodes(selectedFileCodes);

    // Open the print options modal
    setIsPrintOptionsModalOpen(true);
  };

  return (
    <>

{!loadingAppointmentDetails ? (

      <div className="">
        {!showAddFileSection ? (
          <>
            <div onClick={handleback} className="mt-3">
              <ArrowBackIosOutlined style={{ cursor: "pointer" }} />
              Back
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                variant="outline-primary"
                onClick={() => setShowAddFileSection(true)}
              >
                <span className="me-2">+</span>
                Add Files
              </Button>
            </div>

            <div className="row shadow p-2 mb-1 justify-content-center">
              <div className="col-sm-12 col-md-4">
                <p className="mb-0" style={{ fontWeight: "600" }}>Case ID#</p>
                <p className="font-bold">{caseid}</p>
              </div>
              <div className="col-sm-12 col-md-6">
                <h6>Reported Problem:</h6>
                <p>{reported || "No problem reported"}</p>
              </div>
            </div>
            <TableContainer
              component={Paper}
              style={{ marginBottom: ".5rem", marginTop: "1rem" }}
            >
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Select
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      SN
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      File Name
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      File Type
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ textAlign: "center" }}
                    ></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newfile &&
                    Array.isArray(newfile) &&
                    newfile.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {(row?.file_type !== "PRESCRIPTION" ||
                            (row?.file_type === "PRESCRIPTION" &&
                              row?.status === false)) && (
                            <Checkbox
                              checked={selectedRows.includes(index)}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => handlepost(row)}
                        >
                          {row?.file_name || ""}
                        </StyledTableCell>

                        <BookletModal
                          isOpen={isModalOpens}
                          onClose={handleCloseModals}
                          hosid={currentHospital.hos_id}
                          pdfData={pdfDatas}
                        />
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ textAlign: "center" }}
                        >
                          {row?.file_type || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.status ? "SHARED" : "PENDING" || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          <RemoveCircle
                            onClick={() => handleDeletePrompt(row, index)}
                            sx={{ fontSize: "15px" }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={selectedRows.length === 0}
                style={{ textAlign: "center" }}
                onClick={handleView}
              >
                View/Generate
              </Button>
              <PrintOptionsModal
                isOpen={isPrintOptionsModalOpen}
                onClose={handlePrintOptionSelected}
                setIsPrintOptionsModalOpen={setIsPrintOptionsModalOpen}
              />
            </div>
            <AppointModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              selectedFileCodes={selectedFileCodes}
              PdfData={pdfData}
              currentHospital={currentHospital}
              case_id={fileId}
              setIsModalOpen={setIsModalOpen}
              setSelectedRows={setSelectedRows}
              fetchappointment={fetchappointment}
            />
          </>
        ) : (
          <AddFileSection />
        )}
      </div>
         ) : (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              className="mx-auto my-5"
            />
          </div>
        )}
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={() => {
            setShowToast(false);
            if (toastType === "success") {
              setShowFileTable(true);
            }
          }}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
};
export default Casefile;
