import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import AuthContext from "../../context/auth-context";
import InputEle from "./InputEle";
import PageModal from "../ui/PageModal";
import Toast from "../ui/Toast";
import { rxOneApi } from "../../utils/api/api";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import TermsNconditions from "./TermsNconditions";
import PrivacPolicy from "./PrivacPolicy";
import { countriesList } from "../Countrylist";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import ModeContext from "../../context/mode-context";
// import NotificationCenter from "../ui/NotificationCenter"; // You'll need to create this component
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
  Typography,
  FormHelperText,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  Badge,
  IconButton,
} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications'; // Make sure to import this or use an alternative icon

const categoryOptions = [
  { label: "Medical Facility (Clinic/Hospital)", value: "medical_facility" },
  { label: "Doctor/Medical Professional", value: "doctor" },
  { label: "Pharmacy", value: "pharmacy" },
  { label: "Test Lab", value: "test_lab" },
];

function FormEle(props) {
  const { page } = props;

  const params = useParams();

  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState(params["*"]);
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91"); // State to hold the selected country code
  const { mode } = useContext(ModeContext);

  // Notification states
  const [notifications, setNotifications] = useState([]);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [showNotificationCenter, setShowNotificationCenter] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [privacyPolicyshow, setPrivacyPolicyShow] = useState(false);
  const privacyPolicyhandleClose = () => setPrivacyPolicyShow(false);
  const privacyPolicyhandleShow = () => setPrivacyPolicyShow(true);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setReferralCode(params["*"]);
    setContactNumber("");
    setFirstName("");
    setLastName("");
    setCategory("");
    setIsLoading(false);
  }, [page]);

  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setModalType("forgot");
    setShowModal(true);
  };

  const handleResendConfEmail = async () => {
    setModalType("resend");
    setShowModal(true);
  };

  const firebaseConfig = {
    apiKey: "AIzaSyDBA7O-a2KiTC5kTVbb3eVd7AExd4onNyw",
    authDomain: "rxone-firebase.firebaseapp.com",
    projectId: "rxone-firebase",
    storageBucket: "rxone-firebase.appspot.com",
    messagingSenderId: "134528895499",
    appId: "1:134528895499:web:61f4045743c9726b69950e",
    measurementId: "G-LT2YW1B7QX",
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  // Set up notification listener when logged in
  useEffect(() => {
    if (!authCtx.isLoggedIn) return;
    
    // This function handles foreground messages
    const unsubscribe = onMessage(messaging, (payload) => {
      
      // Create a notification object with timestamp
      const newNotification = {
        ...payload,
        timestamp: new Date().toISOString(),
        read: false
      };
      
      // Update notifications state with new notification
      setNotifications(prev => [newNotification, ...prev]);
      setHasNewNotifications(true);
      
      // Show a toast notification
      setShowToast(true);
      setToastType("info");
      setToastMessage(payload.notification?.title || "New notification received");
    });
    
    // Fetch existing notifications from backend
    fetchNotifications();
    
    // Clean up the listener when component unmounts
    return () => unsubscribe();
  }, [authCtx.isLoggedIn]);

  // Fetch notifications from the backend
  const fetchNotifications = async () => {
    try {
      const userToken = localStorage.getItem("usr_token");
      if (!userToken) return;
      
      // Assuming you have an endpoint to get notifications
      // Update your endpoint structure as needed
      const response = await rxOneApi.get(
        `${RX_ONE_ENDPOINTS.NOTIFICATION.NOTIFICATION}/list/${userToken}`
      );
      
      if (response.data && Array.isArray(response.data.notifications)) {
        setNotifications(response.data.notifications);
        setHasNewNotifications(
          response.data.notifications.some(notif => !notif.read)
        );
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Mark all notifications as read
  const markNotificationsAsRead = async () => {
    try {
      const userToken = localStorage.getItem("usr_token");
      if (!userToken) return;
      
      // Assuming you have an endpoint to mark notifications as read
      await rxOneApi.post(
        `${RX_ONE_ENDPOINTS.NOTIFICATION.NOTIFICATION}/mark-read/${userToken}`
      );
      
      // Update local state
      setNotifications(prev => 
        prev.map(notif => ({ ...notif, read: true }))
      );
      setHasNewNotifications(false);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  // Toggle notification center visibility
  const toggleNotificationCenter = () => {
    setShowNotificationCenter(prev => !prev);
    if (!showNotificationCenter && hasNewNotifications) {
      markNotificationsAsRead();
    }
  };

  const showNotificationAlert = () => {
    return new Promise((resolve) => {
      const result = window.confirm("Would you like to receive notifications?");
      resolve(result);
    });
  };

  const requestNotificationPermission = async () => {
    try {
      const userChoice = await showNotificationAlert();

      if (userChoice) {
        // User clicked "Yes" in the custom alert
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          // User granted permission in the browser prompt
          const token = await getToken(messaging, {
            vapidKey:
              "BGIQb9nlWxvawDdRSHVL-Sm6Jh64D09Qfkwbw8hYqG01EhP9ChWvAGJAdWcJVMNszA0cmVtrK43xcp2oz3DHbS8",
          });
          const userToken = localStorage.getItem("usr_token");
          const userSecret = localStorage.getItem("usr_secret");
          
          await rxOneApi.post(
            RX_ONE_ENDPOINTS.NOTIFICATION.NOTIFICATION + "/" + userToken,
            {
              device_type: "web",
              device_token: token,
            },
            {
              headers: {
                usr_secret: userSecret,
                "Content-Type": "application/json",
              },
            },
          );

          
          // After registering, fetch current notifications
          fetchNotifications();
        }
      }
    } catch (error) {
      console.error(
        "An error occurred while setting up notifications: ",
        error
      );
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      // Validate category for signup
      if (page === "sign-up" && category === "") {
        throw new Error("Please select or enter a category");
      }

      setIsLoading(true);

      // Prepare request data
      let endPoint;
      let body = {};

      if (page === "sign-up") {
        endPoint = RX_ONE_ENDPOINTS.USER.SIGN_UP;
        body = {
          email,
          phone: phoneCode + contactNumber,
          firstname: firstName,
          lastname: lastName,
          password,
          terms_agreement: "Yes",
          referral_code: referralCode,
          category: category.toLowerCase(),
        };
      } else {
        endPoint = RX_ONE_ENDPOINTS.USER.LOGIN;
        body = {
          email,
          password,
        };
      }

      const res = await rxOneApi.post(endPoint, body);

      if (!res) {
        throw new Error("Something went wrong. Please try later.");
      }

      if (page === "login") {
        const userToken = res.data.usr_token;
        const userSecret = res.data.usr_secret;

        // Store user secret and update headers
        localStorage.setItem("usr_secret", userSecret);
        localStorage.setItem('menu_list', JSON.stringify(res.data.menu_list));

        rxOneApi.setUserSecretAuthHeaders();

        // Fetch user keys
        const userTestKeysRes = await rxOneApi.get(
          RX_ONE_ENDPOINTS.USER.KEYS + "/test/" + userToken
        );
        const userLiveKeysRes = await rxOneApi.get(
          RX_ONE_ENDPOINTS.USER.KEYS + "/live/" + userToken
        );

        const userKeys = {
          test: { ...userTestKeysRes.data },
          live: { ...userLiveKeysRes.data },
        };

        localStorage.setItem("usr_keys", JSON.stringify(userKeys));
        authCtx.login(userToken, userSecret, email);
        navigate("/app/home", { replace: true });

        // Handle notification permission after delay
        await new Promise((resolve) => {
          setTimeout(async () => {
            await requestNotificationPermission();
            resolve();
          }, 2000);
        });
        
        // Fetch notifications after login
        fetchNotifications();
      } else {
        // Handle signup success
        setShowToast(true);
        setToastType("success");
        setToastMessage(res.data.message);
        navigate("/user/login");
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      // Improved error message handling
      setToastMessage(
        error?.response?.data?.message ||
          error?.error?.message ||
          error?.message ||
          "An unexpected error occurred"
      );
      setIsLoading(false);
    }
  };

  const handleChanges = (event) => {
    setPhoneCode(event.target.value);
  };

  // Render notification icon with badge if there are new notifications
  const renderNotificationIcon = () => {
    if (!authCtx.isLoggedIn) return null;
    
    return (
      <IconButton 
        color="primary" 
        onClick={toggleNotificationCenter}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <Badge badgeContent={hasNewNotifications ? '•' : 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    );
  };

  return (
    <>
      {renderNotificationIcon()}
      
      {showNotificationCenter && (
        <div className="notification-center" style={{
          position: 'absolute',
          top: '50px',
          right: '10px',
          width: '300px',
          maxHeight: '400px',
          overflowY: 'auto',
          background: 'white',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '10px',
          zIndex: 1000,
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <h4 style={{ margin: 0 }}>Notifications</h4>
            <Button size="small" onClick={toggleNotificationCenter}>Close</Button>
          </div>
          
          {notifications.length === 0 ? (
            <p>No notifications</p>
          ) : (
            notifications.map((notification, index) => (
              <div 
                key={index} 
                style={{
                  padding: '8px',
                  marginBottom: '8px',
                  borderBottom: '1px solid #eee',
                  backgroundColor: notification.read ? 'transparent' : '#f0f7ff'
                }}
              >
                <h5 style={{ margin: '0 0 5px 0' }}>
                  {notification.notification?.title || 'Notification'}
                </h5>
                <p style={{ margin: '0 0 5px 0' }}>
                  {notification.notification?.body || notification.message || 'No content'}
                </p>
                <small style={{ color: '#777' }}>
                  {new Date(notification.timestamp).toLocaleString()}
                </small>
              </div>
            ))
          )}
        </div>
      )}

      <Form onSubmit={handleFormSubmit}>
        <InputEle
          type="email"
          name="email-address"
          value={email}
          setValue={setEmail}
        />

        {page === "sign-up" ? (
          <>
            <Row className="align-items-center">
              <Col xs={3} className="pe-0">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phoneCode}
                  onChange={handleChanges}
                  className="w-23"
                >
                  {countriesList
                    .slice()
                    .sort((a, b) => a.code.localeCompare(b.code))
                    .map((country, index) => (     
                      <MenuItem       
                        key={`${country.code}-${index}`}       
                        value={country.code}       
                        style={{marginRight: "5rem  !important"}}     
                      >       
                        {country.code}     
                      </MenuItem>   
                    ))
                  } 
                </Select>
              </Col>

              <Col xs={9} className="ps-1">
                <InputEle
                  type="tel"
                  name="contact-number"
                  value={contactNumber}
                  setValue={setContactNumber}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputEle
                  type="text"
                  name="first-name"
                  value={firstName}
                  setValue={setFirstName}
                  className="mt-0 mb-0"
                />
              </Col>

              <Col>
                <InputEle
                  type="text"
                  name="last-name"
                  value={lastName}
                  setValue={setLastName}
                  className="mt-0 mb-0"
                />
              </Col>
            </Row>
          </>
        ) : null}

        <InputEle
          page={page}
          type="password"
          name="password"
          value={password}
          setValue={setPassword}
        />

        {page === "sign-up" && (
          <>
            <CreatableSelect
              options={categoryOptions}
              className="form-control my-2 p-0 border-0"
              styles={{
                input: (inputStyles) => ({
                  ...inputStyles,
                  padding: "0.5rem",
                }),
                placeholder: (placeholderStyles) => ({
                  ...placeholderStyles,
                  fontSize: "1.25rem",
                }),
              }}
              placeholder="Category"
              onChange={(option) => setCategory(option.value)}
            />

            <InputEle
              page={page}
              type="text"
              name="referral-code"
              value={referralCode}
              setValue={setReferralCode}
              notRequired={true}
              disabled={params["*"] !== ""}
            />
          </>
        )}

        {page === "sign-up" && (
          <>
            <div className="float-start mt-2" style={{ fontSize: "0.85rem" }}>
              By continuing, you agree to the{" "}
              <Link to="/agreement" className="text-decoration-underline">
                RxOne Customer Agreement
              </Link>{" "}
              and the{" "}
              <Link to="/privacy-policy" className="text-decoration-underline">
                Privacy Policy
              </Link>
              . This site uses essential cookies.
            </div>
          </>
        )}

        {page === "login" && (
          <div className="mt-2">
            <span
              className="px-2"
              onClick={handleForgotPassword}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Forgot Password
            </span>
            <span
              className="px-2"
              onClick={handleResendConfEmail}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Resend Confirmation Link
            </span>
          </div>
        )}

        <button
          type="submit"
          className="register-and-login-btn float-end mt-3"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mx-3"
            />
          ) : page === "sign-up" ? (
            "Sign Up"
          ) : (
            "Login"
          )}
        </button>
      </Form>

      <PageModal
        type={modalType}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <Toast type={toastType} show={showToast} handleToastClose={setShowToast}>
        {toastMessage}
      </Toast>
    </>
  );
}

export default FormEle;