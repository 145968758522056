import { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ModeContext from "../../context/mode-context";
import { rxOneApi } from "../../utils/api/api";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import Toast from "../ui/Toast";
import { TextField, Button, IconButton, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RoleModal = ({ isOpen, onClose, fetchNotifications, onRoleAdded, initialRoleName }) => {
  const [rolename, setRolename] = useState(initialRoleName || "");
  const { mode } = useContext(ModeContext);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  // Reset rolename when modal opens with new initialRoleName
  useEffect(() => {
    if (isOpen) {
      setRolename(initialRoleName || "");
    }
  }, [isOpen, initialRoleName]);

  const handlesubmit = async () => {
    const body = {
      staff_role: rolename,
    };
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      const res = await rxOneApi.post(
        RX_ONE_ENDPOINTS.SETTINGS.SUBMIT_ROLE + "/" + mode + "/" + userToken,
        body,
        {
          headers: {
            usr_secret: userSecret,
            "Content-Type": "application/json",
          },
        }
      );

      if (res) {
        // Create the same format object as your roleOptions
        const newRole = {
          label: rolename,
          value: res.data.role_id // Assuming the API returns the new role ID
        };
        onRoleAdded(newRole); // Pass the formatted role object
        setRolename("");
        onClose();
        await fetchNotifications();
      }
    } catch (error) {
      onClose();
      setShowToast(true);
      setToastType("error");
      setToastMessage("Invalid session. Please login again.");
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        size="lg"
        centered
        contentClassName="rounded-lg shadow-lg"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          pb={0}
        >
          <Typography variant="h5" align="center" style={{ flex: 1 }}>
            Add Role
          </Typography>
          <IconButton
            onClick={onClose}
            style={{ alignSelf: "flex-start" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Modal.Body className="p-6">
          <TextField
            fullWidth
            margin="normal"
            id="role-name"
            label="Role Name"
            variant="outlined"
            value={rolename}
            onChange={(e) => setRolename(e.target.value)}
            autoFocus
          />
        </Modal.Body>

        <Modal.Footer className="border-t p-4">
          <Box className="flex gap-3 justify-end w-full">
            <Button
              variant="contained"
              onClick={handlesubmit}
              className="px-6"
              disabled={!rolename.trim()}
            >
              Save
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>

      {showToast && (
        <Toast
          open={showToast}
          type={toastType}
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />
      )}
    </>
  );
};

export default RoleModal;
