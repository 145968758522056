
import React from "react";
import PageContentLayout from "../components/page-content/PageContentLayout";
import PageNav from "../components/page-content/PageNav";
import PageContentRoutes from "../utils/app-content-routes";
import OrderTab from "../components/order/orderTab";
// const facilitiesRoutes = [
//   { path: "book-let", component: <BookletTab /> },

// ];

const oorderRoute = [
  { path: "order", component: <OrderTab />, name:"Orders" },
  // {
  //   path: "payments",
  //   component: <PaymentsTab />,
  // },
  // { path: "Orders", component: showOrderDetails?<OrdersModal sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/>:<OrdersTab sendOrderData={setOrderData} openOrderDetails={openOrderDetails}/> },
  // { path: "invoices", component: <InvoicesTab /> },
  // { path: "Trends", component: <TrendsTab /> },
];

function Order() {
  return (
    <PageContentLayout>
    <PageNav routes={oorderRoute} />
    
    <PageContentRoutes routes={[...oorderRoute]} />
      
      {/* { path: "orders/details", component: <DisplayOrder/> }, { path: "orders/create", component: <AddOrderForm /> } */}

  
  </PageContentLayout>
  );
}

export default Order;