import React from 'react';
import Nav from "react-bootstrap/Nav";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import SignpostIcon from "@mui/icons-material/Signpost";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SidebarNavItem from "./SidebarNavItem";

const iconMap = {
  HOME: HomeIcon,
  OPD: EventIcon,
  BILLING: MonetizationOnOutlinedIcon,
  PATIENTS: LocalHotelIcon,
  DOCTORS: PersonAddAlt1Icon,
  FACILITIES: SignpostIcon,
  SETTLEMENTS: HandshakeIcon,
  REWARDS: EmojiEventsOutlinedIcon,
  KIOSK: ScreenshotMonitorIcon,
  BOOKLET: ImportContactsIcon,
  SETTINGS: SettingsIcon,
  KYC: AccountBalanceIcon,
  ORDERS: LocalAtmIcon,
};

function SidebarNav() {
  // Get menu list from localStorage (assuming it's stored during login)
  const menuList = JSON.parse(localStorage.getItem('menu_list') || '[]')
  .filter(item => item !== 'KYC');  
  
  // Always include Home as the first item
  const fullMenuList = ['HOME', ...menuList];
  
  return (
    <Nav
      as="nav"
      className="flex-column justify-content-center py-2 fw-bold sidebar"
    >
      {fullMenuList.map((menuItem) => {
        const Icon = iconMap[menuItem] || SignpostIcon; // Default to SignpostIcon if no match
        
        // Make sure "ORDERS" links to "orders" path
        return (
          <SidebarNavItem
            key={menuItem}
            Icon={Icon}
            label={menuItem === 'HOME' ? 'Home' : menuItem.charAt(0) + menuItem.slice(1).toLowerCase()}
            link={menuItem.toLowerCase()}
          />
        );
      })}
    </Nav>
  );
}

export default SidebarNav;