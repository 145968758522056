// Info.jsx
import { Col, Row } from "react-bootstrap";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function Info(props) {
  const { title, body } = props;
  
  return (
    <Row xs={12} className="my-4 h-100">
      <Col as="section" xs={12} className="mt-3 mt-sm-0">
      <h2 className="mb-3" style={{ fontSize: "22px" }}>  
          <span 
            className="d-inline-flex justify-content-center align-items-center me-2 rounded-circle " 
            style={{ width: "30px", height: "30px" }}
          >
            <TaskAltIcon fontSize="small" style={{ color: "white" }} />
          </span>
          {title}
        </h2>
        <p style={{ fontSize: "1.15rem" }}>{body}</p>
      </Col>
    </Row>
  );
}

export default Info;