import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";
import {
  faEnvelope,
  faPhone,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOneApi } from "../../utils/api/api";
import Toast from "../ui/Toast";
import AddUserModal from "./AddUserModal";
import CreatableSelect from "react-select/creatable";
import { FormControl } from "@mui/material";
import ModeContext from "../../context/mode-context";

const Card = (props) => {
  const {
    first_name,
    last_name,
    email,
    activated,
    phone,
    access_allowed,
    staff_id,
    role,
    role_id,
    disable_role_change
  } = props.record;

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      border: "2px solid #b3c6e7",
      borderRadius: "0",
      "&:hover": {
        border: "2px solid #b3c6e7",
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "0",
    }),
  };

  const { setShallUpdate } = props;

  const [updatingAccess, setUpdatingAccess] = useState(false);
  const [accessAllowed, setAccessAllowed] = useState(access_allowed);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(
    role && role_id ? { label: role, value: role_id } : null
  );
  const { mode } = useContext(ModeContext);
  const [roles, setRoles] = useState([]);
  const [assigningRole, setAssigningRole] = useState(false);
  const [pendingRoleChange, setPendingRoleChange] = useState(null);
  const [showConfirmToast, setShowConfirmToast] = useState(false);

  const handleActivatedContactClick = () => {
    if (activated) return;
    setShowAddUserModal(true);
  };

  const handleRoleChange = (selectedOption) => {
    // Store the selected option temporarily
    setPendingRoleChange(selectedOption);
    
    // If it's a new role (created through the CreatableSelect)
    if (selectedOption.__isNew__) {
      setToastMessage("Creating new roles is not supported");
      setToastType("error");
      setShowToast(true);
      return;
    }

    // Only show confirmation if the selected role is different from current one
    if (selectedOption.value !== role_id) {
      setToastMessage(`Would you like to change the role to "${selectedOption.label}"?`);
      setToastType("confirm");
      setShowConfirmToast(true);
    } else {
      // If same role selected, just update UI state
      setSelectedRole(selectedOption);
    }
  };

  const confirmRoleChange = async () => {
    try {
      setAssigningRole(true);
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      const response = await rxOneApi.post(
        `${RX_ONE_ENDPOINTS.SETTINGS.ROLE_ASSIGN}/${userToken}/${staff_id}`,
        { role_id: pendingRoleChange.value },
        { headers: { usr_secret: userSecret } }
      );

      if (response.data) {
        // Update selectedRole immediately to show in dropdown
        setSelectedRole(pendingRoleChange);
        setToastMessage("Role assigned successfully");
        setToastType("success");
        setShowToast(true);
        // Optionally refresh the parent component
        if (setShallUpdate) {
          setShallUpdate(true);
        }
      }
    } catch (error) {
      setToastMessage(error.message || "Failed to assign role");
      setToastType("error");
      setShowToast(true);
    } finally {
      setAssigningRole(false);
      setShowConfirmToast(false);
      setPendingRoleChange(null);
    }
  };

  const cancelRoleChange = () => {
    setShowConfirmToast(false);
    setPendingRoleChange(null);
  };

  const handleAvailabilityToogle = async () => {
    try {
      setUpdatingAccess(true);
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      const res = await rxOneApi.axios.put(
        RX_ONE_ENDPOINTS.SETTINGS.SWITCH_STAFF_ACCESS +
          "/" +
          userToken +
          "/" +
          staff_id,
        { access_allowed: !accessAllowed },
        { headers: { usr_secret: userSecret } }
      );
      if (res) {
        setAccessAllowed(res.data.access_allowed);
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setUpdatingAccess(false);
    }
  };

  const options = roles.map((role) => ({
    label: role.role_name,
    value: role.role_id,
  }));

  const fetchNotifications = async () => {
    const userToken = localStorage.getItem("usr_token");
    rxOneApi.setUserSecretAuthHeaders();
    try {
      const res = await rxOneApi.get(
        RX_ONE_ENDPOINTS.SETTINGS.FETCH_ROLES + "/" + mode + "/" + userToken
      );
      if (res && res.data.all_roles?.length > 0) {
        setRoles(res.data.all_roles);
        
        // If there's an existing role_id from props
        if (role_id) {
          // Find the matching role in the fetched roles
          const matchingRole = res.data.all_roles.find(r => r.role_id === role_id);
          if (matchingRole) {
            // Set the selected role with the correct label and value
            setSelectedRole({
              label: matchingRole.role_name,
              value: matchingRole.role_id
            });
          }
        }
        // Only set the first role if there isn't already a role_id
        else if (!selectedRole && !role_id) {
          const firstRole = {
            label: res.data.all_roles[0].role_name,
            value: res.data.all_roles[0].role_id,
          };
          setSelectedRole(firstRole);
        }
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage("Invalid session. Please login again.");
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);


  useEffect(() => {
    // console.log("Current selectedRole:", selectedRole);
    // console.log("Available options:", options);
  }, [selectedRole, options]);

  return (
    <>
      <div className="m-2 shadow">
        <div className="card mb-3 border rounded bg-white">
          <div className="card-body">
            <div className="d-flex flex-wrap justify-content-between align-items-center container-fluid">
              <Row className="mx-0 w-100 justify-content-center align-items-center">
                <Col xs={12} lg={3} md={6}>
                  <div className="d-flex flex-column">
                    <h5 className="card-title mb-0">
                      {first_name + " " + last_name}
                    </h5>
                    <a
                      href={`mailto:${email}`}
                      className="d-flex align-items-center text-dark text-decoration-none"
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                      <p className="card-text mb-0 p-1">{email}</p>
                    </a>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faPhone} className="mr-1" />
                      <p className="card-text mb-0 ml-3">{phone}</p>
                    </div>

                    <FormControl style={{ width: "100%" }}>
                    <CreatableSelect
  isLoading={assigningRole}
  options={options}
  onChange={handleRoleChange}
  styles={customStyles}
  value={selectedRole}  // This is what displays the selected value
  isClearable={false}
  placeholder="Assign role"
  formatCreateLabel={(inputValue) =>
    `Add "${inputValue}"`
  }
  isDisabled={disable_role_change}
  className="w-1/2"
/>
                    </FormControl>
                  </div>
                </Col>

                <Col xs={12} lg={3} md={6}>
                  <div className="d-flex flex-column align-items-center">
                    <label className="text-success">Allow Access</label>
                    <Form.Check
                      className="form-switch-lg mt-1"
                      disabled={updatingAccess}
                      type="switch"
                      checked={accessAllowed}
                      onChange={handleAvailabilityToogle}
                      style={{ transform: "scale(1.5)" }}
                    />
                  </div>
                </Col>

                <Col xs={12} lg={3} md={6}>
                  <div className="d-flex flex-column">
                    <div
                      className={`d-flex ${
                        activated ? "bg-success" : "btn-warning"
                      } rounded justify-content-center p-2 align-items-center mt-3 mb-1 w-95 my-lg-0`}
                      style={{ cursor: activated ? "" : "pointer" }}
                      disabled={activated ? true : false}
                      onClick={handleActivatedContactClick}
                    >
                      <p className="card-text text-light mb-0 p-1">
                        {activated ? "Contact Verified" : "Update Contact"}
                      </p>
                      <FontAwesomeIcon
                        icon={activated ? faCheckCircle : faEdit}
                        className="text-light mr-2"
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={12} lg={3} md={6}>
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-outline-warning rounded-pill my-1 w-95 my-lg-0"
                      onClick={() => setShowAddUserModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="text-warning mr-5"
                      />
                      Update Details
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      {showAddUserModal && (
        <AddUserModal
          operation="edit"
          show={showAddUserModal}
          onHide={() => setShowAddUserModal(false)}
          staffId={staff_id}
          setShallUpdate={setShallUpdate}
        />
      )}

      {/* Regular Toast for messages */}
      <Toast type={toastType} show={showToast} handleToastClose={setShowToast}>
        {toastMessage}
      </Toast>

      {/* Centered Confirmation Toast for role change */}
      {showConfirmToast && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" 
             style={{ zIndex: 1050, backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="bg-white rounded shadow" style={{ minWidth: "350px", maxWidth: "450px" }}>
            <div className="bg-warning text-white p-3">
              <div className="d-flex justify-content-between align-items-center">
                <strong>Confirm Role Change</strong>
                <button 
                  type="button" 
                  className="btn-close"
                  onClick={cancelRoleChange}
                ></button>
              </div>
            </div>
            <div className="p-4">
              <div className="mb-3">
                <strong>Selected Role: </strong>
                <span className="badge bg-info text-dark">{pendingRoleChange?.label}</span>
              </div>
              
              <p>{toastMessage}</p>
              
              <div className="d-flex justify-content-end mt-4">
                <button
                  className="btn btn-secondary me-2"
                  onClick={cancelRoleChange}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={confirmRoleChange}
                  disabled={assigningRole}
                >
                  {assigningRole ? "Processing..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;