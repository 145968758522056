import { useState, useContext, useEffect } from "react";
import DateRange from "../date-range/DateRange";
import CachedIcon from "@mui/icons-material/Cached";
import Button from "../ui/Button";
import SearchIcon from "../../assets/images/static/SearchIcon";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import Map from "../form/Map";
import { Typography } from "@material-ui/core";
import { countriesList } from "../Countrylist";
import NoPatientProfile from "../opd/appointments-form/NoPatientProfile";
import PatientForm from "../opd/appointments-form/PatientForm";
import Select from "react-select";
import ProfileCard from "../opd/appointments-form/ProfileCard";
import ModeContext from "../../context/mode-context";
import { Spinner, Card, Table } from "react-bootstrap";
import { FaCheck } from "react-icons/fa"; // You'll need to install react-icons: npm install react-icons
import Toast from "../ui/Toast";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const OrderModal = ({
  open,
  onClose,
  fetchOrder,
  startDate,
  EndDate,
  setIsModalOpen,
}) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTab, setSelectedTab] = useState("test"); // Default tab is 'test'
  const { currentHospital } = useContext(HospitalsContext);
  const [partners, setPartners] = useState([]);
  const [plans, setPlans] = useState([]);
  const [noPatientProfileMsg, setNoPatientProfileMsg] = useState(null);
  const [showNoPatientsModal, setShowNoPatientsModal] = useState(false);
  const [createPatientForm, setCreatePatientForm] = useState(false);

  const [orderForm, setOrderForm] = useState({
    patientName: "",
    deliveryLocation: "",
    // Load country code from localStorage if available, otherwise use default "+91"
    countryCode: localStorage.getItem("userCountryCode") || "+91",
    // Load phone from localStorage if available, otherwise empty string
    phone: localStorage.getItem("userPhone") || "",
    email: "",
    date: null,
    time: null,
    tests: [],
    partner: "",
    checkup: "",
    google_place_id: "",
    google_geo_lat: "",
    google_geo_lng: "",
    address: "",
    patientId: "",
  });

  const [showSelectionFields, setShowSelectionFields] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [getMapData, setMapData] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 20,
  });
  useEffect(() => {
    if (selectedType === "diagnostic") {
      setOrderForm((prev) => ({
        ...prev,
        phone: "", // Reset the phone field
      }));
    }
  }, [selectedType]);
  const [testCards, setTestCards] = useState([]);
  const [packageCards, setPackageCards] = useState([]);
  const [parameterCards, setParameterCards] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [patientProfiles, setPatientProfiles] = useState([]);
  const[patientphone, setPatientphone] = useState("");
  const [showPatientProfilesModal, setShowPatientProfilesModal] =
    useState(false);
  const [editProfileId, setEditProfileId] = useState(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [aredataLoading, setAredataLoading] = useState(false);
  const [orderConfirmation, setOrderConfirmation] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [profile, setProfile] = useState("");

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const saveGoogleMapValues = () => {
    setMapData(orderForm);
    handleCloseMapModal();
  };

  useEffect(() => {
    setOrderForm((prev) => ({
      ...prev,
      countryCode: localStorage.getItem("userCountryCode") || "+91",
      phone: localStorage.getItem("userPhone") || "",
    }));
  }, []);

  const validatePhone = () => {
    const phoneRegex = /^\d{6,10}$/;
    if (!phoneRegex.test(orderForm.phone)) {
      setPhoneError("Phone number must be between 6-10 digits");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  };

  const validateRequiredFields = () => {
    // Check phone, delivery location, date and time
    if (!orderForm.phone || phoneError) {
      return false;
    }
    
    if (!getMapData.deliveryLocation) {
      return false;
    }
    
    if (!orderForm.date || !orderForm.time) {
      return false;
    }
    
    // If showing test selection, validate partner selection
    if (showSelectionFields && !orderForm.partner) {
      return false;
    }
    
    // Ensure at least one test is selected if not in selection mode
    if (!showSelectionFields && orderForm.tests.length === 0) {
      return false;
    }
    
    return true;
  };
  
  // Sequential form access logic - add these functions
  const canAccessDeliveryLocation = () => {
    return orderForm.phone && !phoneError;
  };
  
  const canAccessDateTime = () => {
    return canAccessDeliveryLocation() && getMapData.deliveryLocation;
  };
  
  const canAccessTests = () => {
    return canAccessDateTime() && orderForm.date && orderForm.time;
  };
  

  const handleUpdateGoogleMapPlace = (values) => {
    setOrderForm({
      ...orderForm,
      google_place_id: values.google_place_id,
      google_geo_lat: values.google_geo_lat,
      google_geo_lng: values.google_geo_lng,
      address: values.address, // Store the formatted address
      deliveryLocation: values.address, // Also update deliveryLocation to show in the input
    });
  };

  useEffect(() => {
    const fetchPartners = async () => {
      setAredataLoading(true);
      try {
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.ORDER.FETCH_PARTNERS + "/" + currentHospital.hos_id
        );

        // Check if records exist in the response and set partners state
        if (res.data && res.data.records) {
          setAredataLoading(false);
          setPartners(res.data.records);
        }
      } catch (err) {
        console.error("Error fetching partners:", err);
      } finally {
        setAredataLoading(false); // Stop loading
      }
    };
    fetchPartners();
  }, [currentHospital]);

  const fetchPlans = async (partnerId, testName) => {
    setAredataLoading(true);

    try {
      const res = await rxOpdApi.get(
        RX_OPD_ENDPOINTS.ORDER.FETCH_PLANS +
          "/" +
          currentHospital.hos_id +
          "/" +
          partnerId +
          `?page_number=${pagination.page}&page_size=${pagination.pageSize}` +
          (testName ? `&test_name=${encodeURIComponent(testName)}` : "")
      );

      if (res.data && res.data.records) {
        setAredataLoading(false);
        setPlans(res.data.records);
        // Process data for test cards
        let testData = [];
        if (res.data.records[0] && res.data.records[0].profile) {
          testData = res.data.records[0].profile.map((item) => ({
            id: item.diagnostic_id,
            name: item.test_name,
            price: item.affiliate_price,
            description:
              item.test_description || `${item.test_name} diagnostic test`,
            currency: item.affiliate_price_currency,
          }));
        }
        setTestCards(testData);

        // Process data for parameter cards
        let parameterData = [];
        if (res.data.records[1] && res.data.records[1].parameter) {
          parameterData = res.data.records[1].parameter.map((item) => ({
            id: item.diagnostic_id,
            name: item.test_name,
            price: item.affiliate_price,
            description:
              item.test_description || `${item.test_name} parameter test`,
            currency: item.affiliate_price_currency,
          }));
        }
        setParameterCards(parameterData);

        // Process data for package cards
        let packageData = [];
        if (res.data.records[2] && res.data.records[2].package) {
          packageData = res.data.records[2].package.map((item) => ({
            id: item.diagnostic_id,
            name: item.test_name,
            price: item.affiliate_price,
            description:
              item.test_description ||
              `${item.test_name} comprehensive package`,
            currency: item.affiliate_price_currency,
          }));
        }
        setPackageCards(packageData);
      }
    } catch (err) {
      setAredataLoading(false);
      console.error("Error fetching plans:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setOrderForm({
      ...orderForm,
      [name]: value,
    });

    // Save phone and country code to localStorage when they change
    if (name === "phone") {
      localStorage.setItem("userPhone", value);
      setPhoneError("");
    }

    if (name === "countryCode") {
      localStorage.setItem("userCountryCode", value);
    }

    if (name === "deliveryLocation") {
      setMapData({
        ...getMapData,
        deliveryLocation: value,
      });
    }
    // If the partner field is changed, fetch the plans for that partner
    if (name === "partner" && value) {
      fetchPlans(value);
    }
  };

  const handleDateChange = (date) => {
    setOrderForm({
      ...orderForm,
      date,
    });
  };

  const handleTimeChange = (time) => {
    setOrderForm({
      ...orderForm,
      time,
    });
  };

  const handleAddTest = () => {
    setShowSelectionFields(!showSelectionFields);
  };

  const handleTestChange = (index, field, value) => {
    const updatedTests = [...orderForm.tests];
    updatedTests[index][field] = value;
    setOrderForm({
      ...orderForm,
      tests: updatedTests,
    });
  };

  const handlephone = async (countryCode, phone) => {
    setAredataLoading(true);

    try {
      // Check if both countryCode and phone are truthy (not null, undefined, empty string)
      const url =
        countryCode && phone
          ? `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.LIST_PATIENTS}/${currentHospital.hos_id}/${countryCode}${phone}`
          : `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.LIST_PATIENTS}/${currentHospital.hos_id}/${orderForm.countryCode}${orderForm.phone}`;

      const res = await rxOpdApi.get(url);

      if (res?.data?.profiles?.length > 0) {
        setPatientProfiles(res.data.profiles);
        setPatientphone(res.data.phone)
        setShowPatientProfilesModal(true);
      } else {
        setShowNoPatientsModal(true);
        setNoPatientProfileMsg(res?.data?.message || "No profiles found.");
      }
    } catch (err) {
      console.error("Error fetching patient profiles:", err);
    } finally {
      setAredataLoading(false); // Stop loading
    }
  };

  const calculateTotalPrice = () => {
    return selectedItems.reduce((total, item) => {
      // Convert to float and add to total
      return total + parseFloat(item.price || 0);
    }, 0);
  };

  const handleNext = async () => {
    setShowConfirmation(true);
  };

  const handleAddItem = (item) => {
    if (!selectedItems.some((selected) => selected.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleRemoveItem = (id) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== id));
  };

  const handleSelectProfile = (profile) => {
    // Update your form with the selected profile data
    setOrderForm((prev) => ({
      ...prev,
      patientName: profile.name,
      patientId: profile.profile_id,
    }));
    setProfile(profile);
    setShowPatientProfilesModal(false);
  };

  const handleEditProfile = (profileId, e) => {
    e.stopPropagation(); // Prevent triggering the onClick of the card

    // Show the profile edit form
    setCreatePatientForm(true);
    setEditProfileId(profileId);
    setIsEditingProfile(true);
  };

  const resetForm = () => {
    setOrderForm({
      countryCode: "+91", // Default country code
      phone: "",
      deliveryLocation: "",
      date: new Date(),
      time: new Date(),
      partner: "",
      tests: [],
      patientName: "",
      patientId: null
    });
    
    // Reset other related states
    setProfile(null);
    setMapData({ deliveryLocation: "" });
    setSelectedItems([]);
    setSearchQuery("");
    setShowSelectionFields(false);
    setPhoneError(null);
  };

  const renderSelectionButtons = () => (
    <div style={{ textAlign: "center", padding: "2rem 0" }}>
      <h2
        style={{ marginBottom: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
      >
        Book Order
      </h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
        <Button
          onClick={() => setSelectedType("diagnostic")}
          style={{
            padding: "1rem 2rem",
            fontSize: "1.1rem",
            backgroundColor: "rgb(77, 154, 166)",
            color: "white",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Diagnostic
        </Button>
        <Button
          disabled // Add the disabled attribute
          onClick={() => setSelectedType("pharmacy")}
          style={{
            padding: "1rem 2rem",
            fontSize: "1.1rem",
            backgroundColor: "rgb(77, 154, 166)",
            color: "white",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Pharmacy
        </Button>
      </div>
    </div>
  );

  const renderCardSection = () => {
    const cards =
      selectedTab === "test"
        ? testCards
        : selectedTab === "package"
        ? packageCards
        : parameterCards; // Default to parameterCards

    const rupay = "\u20B9";
    return (
      <div style={{ marginTop: "2rem" }}>
        {/* Tab selector */}
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "1rem",
          }}
        >
          {["test", "package", "parameter"].map((tab) => (
            <div
              key={tab}
              onClick={() => setSelectedTab(tab)}
              style={{
                padding: "0.75rem 1.5rem",
                cursor: "pointer",
                borderBottom:
                  selectedTab === tab ? "2px solid rgb(77, 154, 166)" : "none",
                color: selectedTab === tab ? "rgb(77, 154, 166)" : "#666",
                fontWeight: selectedTab === tab ? "600" : "normal",
              }}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </div>
          ))}
        </div>

        {/* Selected Items Input */}

        {/* Card grid */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "1rem",
            maxHeight: "250px",
            overflowY: "auto",
            padding: "0.5rem",
          }}
        >
          {cards.map((card) => (
            <div
              key={card.id}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  marginBottom: "0.5rem",
                }}
              >
                {card.name}
              </div>
              <div style={{ fontSize: "0.8rem", color: "#666", flexGrow: 1 }}>
                {card.description}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0.75rem",
                }}
              >
                <div style={{ fontWeight: "bold", color: "rgb(77, 154, 166)" }}>
                  {rupay}
                  {card.price}
                </div>
                <button
                  onClick={() => handleAddItem(card)}
                  style={{
                    backgroundColor: "rgb(77, 154, 166)",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "0.25rem 0.5rem",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderDiagnosticForm = () => (
    <>
      {!showPatientProfilesModal && !showConfirmation && (
        <div style={{ padding: "1.5rem" }}>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "1.5rem",
              fontSize: "1.3rem",
              fontWeight: "bold",
            }}
          >
            Book Diagnostic Order
          </h2>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
              {profile && (
    <div 
      style={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "rgb(77, 154, 166, 0.2)",
        padding: "0.5rem 0.75rem",
        borderRadius: "20px",
        alignSelf: "flex-start",
        marginBottom: "0.25rem",
        border: "1px solid rgb(77, 154, 166)",
      }}
    >
      <span style={{ fontWeight: "500", marginRight: "0.5rem" }}>
        {profile.name}
      </span>
      <span 
        onClick={() => setProfile(null)} 
        style={{
          cursor: "pointer",
          color: "rgb(77, 154, 166)",
          fontWeight: "bold",
        }}
      >
        ×
      </span>
    </div>
  )}
      <div>
  <label
    style={{
      display: "block",
      marginBottom: "0.5rem",
      fontSize: "0.9rem",
      fontWeight: "500",
    }}
  >
    Phone*
  </label>
  <div style={{ display: "flex", alignItems: "center" }}>
    <select
      name="countryCode"
      value={orderForm.countryCode || "+91"}
      onChange={handleInputChange}
      style={{
        padding: "0.75rem",
        border: "1px solid #ccc",
        borderRadius: "5px 0 0 5px",
        marginRight: "0px",
        backgroundColor: "white",
        width: "85px",
        flexShrink: 0,
        borderRight: "none",
      }}
    >
      {countriesList.map((country) => (
        <option key={country.code} value={country.code}>
          {country.code}
        </option>
      ))}
    </select>
    <input
      type="tel"
      name="phone"
      value={orderForm.phone}
      onChange={handleInputChange}
      onBlur={validatePhone}
      style={{
        width: "100%",
        padding: "0.75rem",
        border: "1px solid #ccc",
        borderRadius: "0 5px 5px 0",
        borderColor: phoneError ? "red" : "#ccc",
      }}
      placeholder="Enter phone number"
      required
    />
    <button
      onClick={handlephone}
      disabled={!orderForm.phone || orderForm.phone.length !== 10 || phoneError}
      style={{
        marginLeft: "8px",
        padding: "0.75rem",
        backgroundColor: (!orderForm.phone || orderForm.phone.length !== 10 || phoneError) 
          ? "#ccc" 
          : "rgb(77, 154, 166)",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: (!orderForm.phone || orderForm.phone.length !== 10 || phoneError) 
          ? "not-allowed" 
          : "pointer",
        opacity: (!orderForm.phone || orderForm.phone.length !== 10 || phoneError) ? 0.7 : 1,
      }}
    >
      <span role="img" aria-label="phone">
        👨‍👩‍👦
      </span>
    </button>
  </div>
  {phoneError && (
    <div
      style={{
        color: "red",
        fontSize: "0.8rem",
        marginTop: "0.3rem",
      }}
    >
      {phoneError}
    </div>
  )}
</div>

{/* Delivery Location - disabled until phone is valid */}
<div>
  <label
    style={{
      display: "block",
      marginBottom: "0.5rem",
      fontSize: "0.9rem",
      fontWeight: "500",
      color: canAccessDeliveryLocation() ? "inherit" : "#999",
    }}
  >
    Delivery Location*
  </label>
  <div style={{ display: "flex", alignItems: "center" }}>
    <input
      type="text"
      name="deliveryLocation"
      value={getMapData.deliveryLocation}
      onChange={handleInputChange}
      style={{
        width: "100%",
        padding: "0.75rem",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: canAccessDeliveryLocation() ? "white" : "#f5f5f5",
      }}
      placeholder="Enter delivery location"
      required
      disabled={!canAccessDeliveryLocation()}
    />
    <button
      onClick={() => setShowMapModal(true)}
      style={{
        marginLeft: "8px",
        padding: "0.75rem",
        backgroundColor: canAccessDeliveryLocation() ? "rgb(77, 154, 166)" : "#ccc",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: canAccessDeliveryLocation() ? "pointer" : "not-allowed",
      }}
      disabled={!canAccessDeliveryLocation()}
    >
      <span role="img" aria-label="map">
        📍
      </span>
    </button>
  </div>
</div>

<Modal
              open={showMapModal}
              onClose={handleCloseMapModal}
              // className={classes.modal}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  maxWidth: "90%",
                  maxHeight: "90%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant="h6" component="h2">
                  Map
                </Typography>
                <div
                  style={{
                    width: "356px",
                    // height: "414px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Map
                    currentValues={getMapData}
                    updateGoogleMapValues={handleUpdateGoogleMapPlace}
                  />
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={saveGoogleMapValues}
                    style={{
                      backgroundColor: "rgb(77, 154, 166)",
                      color: "white",
                      border: "none",
                      borderRadius: "10px",
                      padding: "8px 16px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>

{/* Date and Time - disabled until delivery location is set */}
<LocalizationProvider dateAdapter={AdapterDateFns}>
  <div style={{ display: "flex", gap: "1rem" }}>
    <div style={{ flex: 1 }}>
      <label
        style={{
          display: "block",
          marginBottom: "0.5rem",
          fontSize: "0.9rem",
          fontWeight: "500",
          color: canAccessDateTime() ? "inherit" : "#999",
        }}
      >
       Schedule Date*
      </label>
      <DatePicker
        value={orderForm.date || new Date()}
        onChange={(date) => handleDateChange(date || new Date())}
        slotProps={{
          textField: {
            fullWidth: true,
            style: {
              padding: "0.75rem",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: canAccessDateTime() ? "white" : "#f5f5f5",
            },
            placeholder: "Select date",
            required: true,
          },
        }}
        disabled={!canAccessDateTime()}
      />
    </div>

    <div style={{ flex: 1 }}>
      <label
        style={{
          display: "block",
          marginBottom: "0.5rem",
          fontSize: "0.9rem",
          fontWeight: "500",
          color: canAccessDateTime() ? "inherit" : "#999",
        }}
      >
       Schedule Time*
      </label>
      <TimePicker
        value={orderForm.time || new Date()}
        onChange={(time) => handleTimeChange(time || new Date())}
        slotProps={{
          textField: {
            fullWidth: true,
            style: {
              padding: "0.75rem",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: canAccessDateTime() ? "white" : "#f5f5f5",
            },
            placeholder: "Select time",
            required: true,
          },
        }}
        disabled={!canAccessDateTime()}
      />
    </div>
  </div>
</LocalizationProvider>

{/* Test Section - disabled until date/time are set */}
<div>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.5rem",
    }}
  >
    <label style={{ 
      fontSize: "0.9rem", 
      fontWeight: "500",
      color: canAccessTests() ? "inherit" : "#999",
    }}>
      Selected Tests
    </label>

    <Button
      onClick={handleAddTest}
      style={{
        backgroundColor: canAccessTests() ? "rgb(77, 154, 166)" : "#ccc",
        color: "white",
        border: "none",
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: canAccessTests() ? "pointer" : "not-allowed",
      }}
      disabled={!canAccessTests()}
    >
      <AddIcon fontSize="small" />
    </Button>
  </div>

  {/* Only render these sections when the + button is clicked AND tests can be accessed */}
  {showSelectionFields && canAccessTests() && (
    <>
     <div>
                    <h3
                      style={{
                        marginBottom: "1rem",
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "rgb(77, 154, 166)",
                      }}
                    >
                      Select Tests
                    </h3>
                  </div>
                  {/* Partner Select Dropdown */}
                  <div>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "0.5rem",
                        fontSize: "0.9rem",
                        fontWeight: "500",
                      }}
                    >
                      Partner*
                    </label>
                    <select
                      name="partner"
                      value={orderForm.partner}
                      onChange={handleInputChange}
                      style={{
                        width: "100%",
                        padding: "0.75rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "white",
                      }}
                      required
                    >
                      <option value="">Select Partner</option>
                      {partners.map((partner) => (
                        <option
                          key={partner.patner_id}
                          value={partner.patner_id}
                        >
                          {partner.patner_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Search Input */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search Test, Checkup, and Parameter"
                      style={{
                        flex: 1,
                        padding: "0.75rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    />
                    <button
                      onClick={() => fetchPlans(orderForm.partner, searchQuery)}
                      style={{
                        backgroundColor: "rgb(77, 154, 166)",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        padding: "0.8rem 1rem",
                        cursor: "pointer",
                      }}
                    >
                      🔍
                    </button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: selectedItems.length > 0 ? "1rem" : "none",
                      flexWrap: "wrap",
                      gap: "0.5rem",
                      border:
                        selectedItems.length > 0 ? "1px solid #e0e0e0" : "none", // Conditional border                padding: "0.5rem",
                      borderRadius: "4px",
                      minHeight: "40px",
                      alignItems: "center",
                    }}
                  >
                    {selectedItems.map((item) => (
                      <div
                        key={item.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          background: "#e0f7fa",
                          padding: "0.25rem 0.5rem",
                          borderRadius: "4px",
                          fontSize: "0.85rem",
                        }}
                      >
                        {item.name}
                        <span
                          onClick={() => handleRemoveItem(item.id)}
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          ❌
                        </span>
                      </div>
                    ))}
                  </div>
      {/* Partner section and test selection UI */}
      {renderCardSection()}
    </>
  )}

  {/* Only render test entries when NOT showing selection fields */}
  {!showSelectionFields && canAccessTests() &&
    orderForm.tests.map((test, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        <input
          type="text"
          value={test.name}
          onChange={(e) =>
            handleTestChange(index, "name", e.target.value)
          }
          style={{
            flex: 3,
            padding: "0.75rem",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
          placeholder="Test name"
        />
        <input
          type="number"
          value={test.price}
          onChange={(e) =>
            handleTestChange(index, "price", e.target.value)
          }
          style={{
            flex: 1,
            padding: "0.75rem",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
          placeholder="Price"
        />
      </div>
    ))}

  {orderForm.tests.length === 0 && !showSelectionFields && canAccessTests() && (
    <div
      style={{
        textAlign: "center",
        padding: "1rem",
        color: "#666",
        backgroundColor: "#f9f9f9",
        borderRadius: "5px",
      }}
    >
      Click the + button to select tests
    </div>
  )}

  {!canAccessTests() && (
    <div
      style={{
        textAlign: "center",
        padding: "1rem",
        color: "#666",
        backgroundColor: "#f5f5f5",
        borderRadius: "5px",
      }}
    >
      Complete previous fields to add tests
    </div>
  )}
</div>

{/* Modify Next button to be disabled when validation fails */}
<div style={{ marginTop: "1rem", textAlign: "right" }}>
  <Button
    onClick={handleNext}
    style={{
      padding: "0.75rem 2rem",
      backgroundColor: validateRequiredFields() ? "rgb(77, 154, 166)" : "#ccc",
      color: "white",
      border: "none",
      borderRadius: "8px",
      fontSize: "1rem",
      cursor: validateRequiredFields() ? "pointer" : "not-allowed",
    }}
    disabled={!validateRequiredFields()}
  >
    Next
  </Button>
</div>
          </div>
        </div>
      )}
      {showPatientProfilesModal && !showConfirmation && (
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <h3
            style={{
              fontSize: "1.5rem",
              fontWeight: "600",
              marginBottom: "0.5rem",
              textAlign: "center",
              backgroundColor: "rgb(0, 176, 240)",
              padding: "10px, 10px, 10px, 10px",
              color: "#fff",
              marginTop: "3rem",
            }}
          >
            Select from available profiles for booking
          </h3>
          <div style={{ maxHeight: "600px", overflowY: "auto" }}>
            {patientProfiles.map((profile) => (
              <ProfileCard
                key={profile.profile_id}
                name={profile.name}
                gender={profile.gender}
                age={profile.age}
                email={profile.email}
                profileType={profile.profile_type}
                phone={patientphone}
                onClick={() => handleSelectProfile(profile)}
                oneditClick={(e) => handleEditProfile(profile.profile_id, e)}
              />
            ))}
          </div>
        </div>
      )}
      {showNoPatientsModal && (
        <NoPatientProfile
          show={showNoPatientsModal}
          onHide={() => setShowNoPatientsModal(false)}
          message={noPatientProfileMsg}
          onProceed={() => {
            setShowNoPatientsModal(false);
            setCreatePatientForm(true);
          }}
          // }}
        />
      )}
      {createPatientForm && (
        <PatientForm
          addToExistingProfile={false}
          show={createPatientForm}
          onHide={() => {
            setCreatePatientForm(false);
            setAredataLoading(false); // Ensure loading is turned off when form is hidden
            handlephone(orderForm.countryCode, orderForm.phone); // Pass countryCode and phone
            setShowPatientProfilesModal(false); // Show the patient profiles modal again
          }}
          profileType="self"
          initialPhoneData={{
            phone: orderForm.phone,
            countryCode: orderForm.countryCode,
          }}
          onProfileCreated={(phoneData) => {
            setOrderForm((prev) => ({
              ...prev,
              phone: phoneData.phone,
              countryCode: phoneData.countryCode,
            }));
          }}
        />
      )}
      {aredataLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {showConfirmation && (
        <OrderConfirmationCard
          data={orderConfirmation}
          onClose={() => {
            setSelectedType(null); // Reset to renderSelectionButtons
          }}
          setorder={setOrderForm}
          fetchOrder={fetchOrder}
          setShowConfirmation={setShowConfirmation}
          setMapData={setMapData}
          EndDate={EndDate}
          startDate={startDate}
          setIsModalOpen={setIsModalOpen}
          setShowSelectionFields={setShowSelectionFields}
          orderForm={orderForm}
          profile={profile}
          getMapData={getMapData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectedType={selectedType}
          setProfile={setProfile}

        />
      )}{" "}
    </>
  );

  const renderPharmacyForm = () => (
    <div style={{ padding: "1.5rem", textAlign: "center" }}>
      <h2
        style={{
          marginBottom: "1.5rem",
          fontSize: "1.3rem",
          fontWeight: "bold",
        }}
      >
        Book Pharmacy Order
      </h2>
      <p>Pharmacy order form will be implemented here.</p>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          maxHeight: "80vh",
          overflowY: "auto",
          width: "500px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          position: "relative", // Add this to make the child position relative to this container
        }}
      >
        <div
          onClick={() => {
            if (selectedType) {
              setSelectedType(null); // Reset to renderSelectionButtons
              resetForm();
              setProfile(null); // Reset profile state
            } else {
              onClose(); // Close the modal if already in renderSelectionButtons
            }
          }}
          style={{
            position: "absolute", // Position the CloseIcon absolutely
            top: "8px", // Adjust the vertical alignment
            right: "8px", // Align it to the right end
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer", // Add a pointer cursor for better UX
          }}
        >
          <CloseIcon style={{ color: "#666", fontSize: "35px" }} />
        </div>
        {!selectedType && renderSelectionButtons()}
        {selectedType === "diagnostic" && renderDiagnosticForm()}
        {selectedType === "pharmacy" && renderPharmacyForm()}
      </div>
    </Modal>
  );
};

const OrderConfirmationCard = ({
  data,
  onClose,
  setorder,
  fetchOrder,
  setShowConfirmation,
  setMapData,
  startDate,
  EndDate,
  setIsModalOpen,
  setShowSelectionFields,
  orderForm,
  profile,
  getMapData,
  selectedItems,
  setSelectedItems,
  selectedType,
  setProfile
}) => {
  const { currentHospital } = useContext(HospitalsContext);
  const [aredataLoading, setAredataLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const orderid = data?.order_id;

  const formatDate = (dateObj) => {
    if (!dateObj || !(dateObj instanceof Date)) return "N/A";
    
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    
    return `${day}-${month}-${year}`;
  };
  
  // Function to format time
  const formatTime = (dateObj) => {
    if (!dateObj || !(dateObj instanceof Date)) return "N/A";
    
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    
    return `${hours}:${minutes}`;
  };
  const lab_test_details_list =
    selectedItems?.map((item) => ({
      diagnostic_id: item.id,
      test_name: item.name,
      currency: item.currency || "INR",
      test_price: item.price,
    })) || [];


  // Handle missing data safely
  const responseData = {
    message: data?.message || "Order Confirmation",
    patient_name: orderForm?.patientName || "N/A",
    phone: orderForm?.countryCode + orderForm?.phone || "N/A",
    email: profile?.email || "N/A",
    address: getMapData?.address || "N/A",
    sample_collection_on_date: formatDate(orderForm?.date) || "N/A",
    sample_collection_on_time: formatTime(orderForm?.time) || "N/A",
    lab_test_details_list: lab_test_details_list,
    estimated_total: data?.estimated_total || "N/A",
  };

  const parsePrice = (price) => {
    // If price is already a number, return it
    if (typeof price === "number") return price;

    // If price is a string, remove non-numeric characters and parse
    if (typeof price === "string") {
      const numericPrice = price.replace(/[^\d.]/g, "");
      return parseFloat(numericPrice) ;
    }

    // If price is undefined or null, return 0
    return 0;
  };
  // State to store manual discount values
  const [manualDiscounts, setManualDiscounts] = useState(
    responseData.lab_test_details_list.reduce((acc, test, index) => {
      acc[index] = test.discount || "₹ 0.00";
      return acc;
    }, {})
  );
  // Handle discount input change
  // Modified handleDiscountChange function to preserve the rupee symbol
  const handleDiscountChange = (index, value) => {
    // If the field is completely empty or only contains the rupee symbol (possibly with spaces)
    if (!value || value.replace(/₹\s*/g, '').trim() === '') {
      setManualDiscounts((prev) => ({
        ...prev,
        [index]: '₹',
      }));
      return;
    }
    
    // Check if value starts with rupee symbol, add it if missing
    if (!value.startsWith('₹')) {
      value = '₹ ' + value.replace(/₹\s*/g, '');
    }
    
    // Extract numeric part (digits and decimals only)
    const numericValue = value.replace(/[^\d.]/g, '');
    
    // Format with rupee symbol without forcing decimal places
    const formattedValue = `₹ ${numericValue}`;
    
    setManualDiscounts((prev) => ({
      ...prev,
      [index]: formattedValue,
    }));
  };
  const formatSampleCollectionDateTime = (date, time) => {
    if (!date || !time) return null;
    
    // Assuming date is in dd-mm-yyyy format and time is in HH:MM format
    const [day, month, year] = date.split('-');
    const formattedDate = `${year}-${month}-${day} ${time}`;
    
    return formattedDate;
  };
  let subtotal = 0;
  let totalDiscount = 0;

  responseData.lab_test_details_list.forEach((test, index) => {
    const price = parsePrice(test.test_price);
    const discount = parsePrice(manualDiscounts[index]) || 0;

    subtotal += price;
    totalDiscount += discount;
  });

  const finalTotal = subtotal - totalDiscount;

  // Handle confirmation
  const handleConfirm = async () => {
    setAredataLoading(true);
    try {
      // Construct payload dynamically
      const payload = {
        patner_id: orderForm?.partner || null,
        pt_profile_id: getMapData?.patientId || null,
        category: selectedType || null,
        location: {
          google_place_id: getMapData?.google_place_id || null,
          latitude: getMapData?.google_geo_lat || null,
          longitude: getMapData?.google_geo_lng || null,
          address: getMapData?.address || null,
        },
        sample_collection_schedule_date: formatSampleCollectionDateTime(
          responseData.sample_collection_on_date, 
          responseData.sample_collection_on_time
        ),
        notes: {
          diagnosis: responseData.lab_test_details_list.map((test) => {
            // Find the corresponding discount using test name
            const matchingDiscount = manualDiscounts[
              Object.keys(manualDiscounts).find(
                (key) => responseData.lab_test_details_list[key]?.test_name === test.test_name
              )
            ];
      
            return {
              diagnostic_id: test.diagnostic_id,
              test_name: test.test_name,
              discount: parsePrice(matchingDiscount) || 0
            };
          }),
        },
        currency: "INR",
        payment_mode: "Cash",
        amount_due: finalTotal,
        cash_order: true
      };
      
      // Send API request
      rxOpdApi.setUserSecretAuthHeaders();
      const res = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.ORDER.CASH_ORDER}/${currentHospital.hos_id}`,
        payload
      );
      if (res) {
        setAredataLoading(false);
        setShowConfirmation(false);
        setShowToast(true);
        setToastType("success");
        setToastMessage(res?.data?.message || "Order confirmed successfully.");
        onClose(); // Close the modal or parent component
        setorder({
          patientName: "",
          deliveryLocation: "",
          countryCode: localStorage.getItem("userCountryCode") || "+91",
          phone: localStorage.getItem("userPhone") || "",
          email: "",
          date: null,
          time: null,
          tests: [],
          partner: "",
          checkup: "",
          patientId: "",
        });
        setMapData("");
        fetchOrder(startDate, EndDate);
        setIsModalOpen(false);
        setShowSelectionFields(false);
        setSelectedItems([]);
        setProfile(null); // Reset the profile state

      }
    } catch (err) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(err?.err?.message || err?.message);

      // Log the full error for debugging
      console.error("Error in handleConfirm:", err);
    }finally{
      setAredataLoading(false);

    }
  };

  return (
    <>
      {aredataLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="w-100" style={{ maxWidth: "500px", margin: "0 auto" }}>
        <Card className="shadow mb-4">
          <Card.Header className="bg-success bg-opacity-25 border-bottom">
            <div className="d-flex align-items-center">
              <div
                className="bg-success rounded-circle p-1 me-2"
                style={{ cursor: "pointer" }}
                onClick={() => setShowConfirmation(false)}
              >
                <KeyboardBackspaceIcon />
                {/* <FaCheck className="text-white" size={14} /> */}
              </div>
              <Card.Title className="text-success mb-0">
                {responseData.message}
              </Card.Title>
            </div>
          </Card.Header>
          <Card.Body className="pt-4">
            {/* Patient Information */}
            <div>
              <h3 className="fs-5 fw-semibold mb-2">Patient Information</h3>
              <div className="row row-cols-2 g-2 fs-6">
                <div className="col text-secondary">Name:</div>
                <div className="col fw-medium">{responseData.patient_name}</div>

                <div className="col text-secondary">Phone:</div>
                <div className="col fw-medium">{responseData.phone}</div>

                <div className="col text-secondary">Email:</div>
                <div className="col fw-medium">{responseData.email}</div>

                <div className="col text-secondary">Address:</div>
                <div className="col fw-medium">{responseData.address}</div>
              </div>
            </div>

            {/* Sample Collection Details */}
            <div className="mt-4">
              <h3 className="fs-5 fw-semibold mb-2">
                Sample Collection Details
              </h3>
              <div className="row row-cols-2 g-2 fs-6">
                <div className="col text-secondary">Date:</div>
                <div className="col fw-medium">
                  {responseData.sample_collection_on_date}
                </div>

                <div className="col text-secondary">Time:</div>
                <div className="col fw-medium">
                  {responseData.sample_collection_on_time}
                </div>
              </div>
            </div>

            {/* Lab Tests Table */}
            <div className="mt-4">
              <h3 className="fs-5 fw-semibold mb-2">Lab Tests</h3>
              <div className="border rounded overflow-hidden">
                <Table className="fs-6 mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th className="text-start">Test Name</th>
                      <th className="text-center">Currency</th>
                      <th className="text-center">Discount</th>
                      <th className="text-end">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseData.lab_test_details_list.map((test, index) => {
                      return (
                        <tr key={index}>
                          <td>{test.test_name}</td>
                          <td className="text-center">{test.currency}</td>
                          <td className="text-center">
                            <input
                              type="text"
                              className="form-control form-control-sm text-center"
                              value={manualDiscounts[index]}
                              onChange={(e) =>
                                handleDiscountChange(index, e.target.value)
                              }
                            />
                          </td>
                          <td className="text-end">{test.test_price}</td>
                        </tr>
                      );
                    })}

                    {/* Totals Calculation */}
                    <tr className="bg-light">
  <td colSpan="3" className="text-end fw-medium">
    Subtotal
  </td>
  <td className="text-end">
    <span style={{ display: "inline-flex", justifyContent: "flex-end", width: "100%" }}>
      <span style={{ width: "15px", textAlign: "left" }}>₹</span>
      <span style={{ textAlign: "right" }}>{subtotal.toFixed(2)}</span>
    </span>
  </td>
</tr>
<tr className="bg-light">
  <td colSpan="3" className="text-end fw-medium">
    Total Discount
  </td>
  <td className="text-end">
    <span style={{ display: "inline-flex", justifyContent: "flex-end", width: "100%" }}>
      <span style={{ width: "15px", textAlign: "left" }}>-</span>
      <span style={{ width: "15px", textAlign: "left" }}>₹</span>
      <span style={{ textAlign: "right" }}>{totalDiscount.toFixed(2)}</span>
    </span>
  </td>
</tr>
<tr className="bg-light fw-medium">
  <td colSpan="3" className="text-end">
    Final Amount
  </td>
  <td className="text-end">
    <span style={{ display: "inline-flex", justifyContent: "flex-end", width: "100%" }}>
      <span style={{ width: "15px", textAlign: "left" }}>₹</span>
      <span style={{ textAlign: "right" }}>{finalTotal.toFixed(2)}</span>
    </span>
  </td>
</tr>
                  </tbody>
                </Table>
              </div>
            </div>

            {/* Instructions */}
            <div className="fs-6 text-secondary pt-2 border-top mt-3">
              <p>
                Your home sample collection has been scheduled. Our phlebotomist
                will arrive at the specified time and location.
              </p>
            </div>
          </Card.Body>
        </Card>

        {/* Confirm Button */}
        <Button
          onClick={handleConfirm}
          className="w-100 d-flex align-items-center justify-content-center"
          variant="success"
          size="lg"
        >
          <FaCheck className="me-2" />
          Confirm Order
        </Button>
      </div>

      <Toast type={toastType} show={showToast} handleToastClose={setShowToast}>
        {toastMessage}
      </Toast>
    </>
  );
};

const OrderTab = () => {
  // Rest of the OrderTab component remains unchanged
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [selectedBubble, setSelectedBubble] = useState("date");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { currentHospital } = useContext(HospitalsContext);
  const { mode } = useContext(ModeContext);
  const { hospitals, changeCurrentHospital } = useContext(HospitalsContext);
  const [selectedDocId, setSelectedDocId] = useState({
    label: "All Doctors",
    value: "all",
  });
  const [selectedHospitalId, setSelectedHospitalId] = useState("");
  const [aredataLoading, setAredataLoading] = useState(false);

  const formatDateForAPI = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchOrder = async (start, end) => {
    setAredataLoading(true);
    try {
      const formattedStartDate = formatDateForAPI(start);
      const formattedEndDate = formatDateForAPI(end);

      const res = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS.ORDER.FETCH_ORDER}/${currentHospital.hos_id}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );

      if (res.data && res.data.records) {
        setAredataLoading(false);
        const transformedData = res.data.records.map((order) => ({
          id: order.id,
          orderNumber: order.id, // Assuming order ID is used as order number
          date: order.created_on_date, // Extracting date
          time: order.sample_collection_schedule_date.split(" ")[1], // Extracting time
          name: order.name, // Assuming this should be replaced with patient name (adjust accordingly)
          phone: order.phone, // Phone number not in response; fetch if available
          email: order.email, // Email not in response; fetch if available
          category: order.category,
          amount: order.amount,
          payment: order.payment_status || "N/A",
          partner: order.patner_id,
          status: order.status,
          statusColor:
            order.status === "OPEN"
              ? "green"
              : order.status === "CLOSED"
              ? "red"
              : "gray",
        }));

        setFilteredAppointments(transformedData);
        setHasMore(transformedData.length >= paginationModel.pageSize);
      }
    } catch (err) {
      console.error("Error fetching orders:", err);
    } finally {
      setAredataLoading(false);
    }
  };

  const handleDateRangeChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    fetchOrder(newStartDate, newEndDate);
  };

  useEffect(() => {
    fetchOrder(startDate, endDate);
  }, [currentHospital]);

  const handlePaginationChange = (newModel) => {
    setPaginationModel(newModel);
    // Fetch data based on new pagination model
  };

  const handleRowClick = (params) => {
    // Handle row click action
    console.log("Row clicked:", params.row);
  };

  const isValidName = (value) => {
    const nameRegex = /^[A-Za-z\s]*$/;
    return nameRegex.test(value);
  };

  const isValidPhone = (value) => {
    const phoneRegex = /^\d*$/;
    return phoneRegex.test(value);
  };

  const bubbles = [
    { id: "date", label: "Date" },
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone" },
  ];

  // Handler for selecting search bubbles
  const handleBubbleSelect = (id) => {
    setSelectedBubble(id);
    setSearchQuery(""); // Clear search input when changing filter type
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (!value.trim()) {
      // If search query is empty, fetch all orders
      fetchOrder(startDate, endDate);
      return;
    }

    if (selectedBubble === "name" && !isValidName(value)) {
      return;
    }

    // Filter logic for name search
    if (selectedBubble === "name") {
      const filteredData = filteredAppointments.filter((appointment) =>
        appointment.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredAppointments(filteredData);
    }
  };

  const handleSearchIconClick = () => {
    if (!searchQuery.trim()) {
      // If search query is empty, fetch all orders
      fetchOrder(startDate, endDate);
      return;
    }

    if (
      (selectedBubble === "name" && !isValidName(searchQuery)) ||
      (selectedBubble === "phone" && !isValidPhone(searchQuery))
    ) {
      return;
    }

    // If name search is valid, filter the results
    if (selectedBubble === "name") {
      const filteredData = filteredAppointments.filter((appointment) =>
        appointment.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredAppointments(filteredData);
    }
  };

  const getErrorMessage = () => {
    if (selectedBubble === "name" && searchQuery && !isValidName(searchQuery)) {
      return "Please enter alphabets only";
    }
    if (
      selectedBubble === "phone" &&
      searchQuery &&
      !isValidPhone(searchQuery)
    ) {
      return "Please enter numbers only";
    }
    return "";
  };

  const getSearchPlaceholder = () => {
    switch (selectedBubble) {
      case "name":
        return "Filter by name";
      case "phone":
        return "Filter by phone";
      default:
        return "Search";
    }
  };

  const handleHospitalChange = ({ value }) => {
    changeCurrentHospital(value);
    setSelectedHospitalId(value); // Set the hospital ID in state
    setSelectedDocId({ label: "All Doctors", value: "all" });
  };

  // Updated columns configuration based on your requirements
  const columns = [
    {
      field: "orderNumber",
      headerName: "ORDER#",
      headerAlign: "left",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "date",
      headerName: "DATE",
      headerAlign: "left",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "time",
      headerName: "TIME",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "name",
      headerName: "NAME",
      headerAlign: "left",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "phone",
      headerName: "PHONE",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "category",
      headerName: "CATEGORY",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "payment",
      headerName: "PAYMENT",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "partner",
      headerName: "PARTNER",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div
          style={{
            fontSize: "12px",
            backgroundColor: params.row.statusColor || "transparent",
            color: params.row.statusColor ? "#fff" : "#000",
            padding: "4px 8px",
            borderRadius: "4px",
            display: "inline-block",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  return (
    <>
      {aredataLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="px-4 py-4">
        <div className="row ">
          <div>Select Facility:</div>
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <Select
              defaultValue={{
                label: (
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_RX_OPD +
                        (mode === "test" ? "test/" : "") +
                        RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO +
                        "/" +
                        currentHospital?.hos_id +
                        "?v=" +
                        Math.random() * Math.random()
                      }
                      height="30px"
                      width="30px"
                      style={{ marginRight: "2px" }}
                      // onError="this.src='https://fastly.picsum.photos/id/40/200/200.jpg?hmac=xkvWvgGjMuaPySCsshiYpLBOaphxinRhPkMRgx-LIYQ';"
                    />
                    {currentHospital?.hosp_name}
                  </div>
                ),
                value: currentHospital?.hos_id,
              }}
              options={hospitals?.map((hospital) => ({
                label: (
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_RX_OPD +
                        (mode === "test" ? "test/" : "") +
                        RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO +
                        "/" +
                        hospital?.hos_id +
                        "?v=" +
                        Math.random() * Math.random()
                      }
                      height="30px"
                      width="30px"
                      onError="this.src='https://fastly.picsum.photos/id/40/200/200.jpg?hmac=xkvWvgGjMuaPySCsshiYpLBOaphxinRhPkMRgx-LIYQ';"
                    />
                    {hospital?.hosp_name}
                  </div>
                ),
                value: hospital?.hos_id,
              }))}
              onChange={(selectedOption) => {
                handleHospitalChange(selectedOption);
                setSelectedDocId({ label: "All Doctors", value: "all" });
              }}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
                container: (containerStyles) => ({
                  ...containerStyles,
                }),
              }}
            />
          </div>
          <div className="col-12 col-md-2">
            <Button
              onClick={openModal}
              style={{
                backgroundColor: "rgb(77, 154, 166)",
                border: "none",
                borderRadius: "10px",
                padding: "0.3rem 0.8rem",
                fontSize: "0.9rem",
                fontWeight: "bold",
                minWidth: "80px", // Decreased from 100px to 80px
                width: "fit-content", // Added to ensure button only takes necessary space
                textAlign: "center",
                display: "inline-block",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Create +
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div
          className="flex items-center gap-4"
          style={{ display: "flex", margin: "1rem" }}
        >
          <p className="newappointment__container__calender__text">
            Search by:
          </p>
          <div
            className="flex gap-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "1rem",
              marginLeft: "1rem",
            }}
          >
            {bubbles.map((bubble) => (
              <Button
                key={bubble.id}
                onClick={() => handleBubbleSelect(bubble.id)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedBubble === bubble.id
                      ? "#199a8e"
                      : "rgb(77, 154, 166)",
                  color: "#fff",
                  padding: "0.5rem 1rem",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                {bubble.label}
              </Button>
            ))}
          </div>
          <div>
            <CachedIcon
              onClick={() => fetchOrder(startDate, endDate)} // Pass startDate and endDate
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        {/* Date Range Filter */}
        {selectedBubble === "date" && (
          <div>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => handleDateRangeChange(date, endDate)}
              setEndDate={(date) => handleDateRangeChange(startDate, date)}
            />
          </div>
        )}

        {/* Search Input */}
        {(selectedBubble === "name" || selectedBubble === "phone") && (
          <div>
            <div className="searchbar">
              <input
                type="text"
                placeholder={getSearchPlaceholder()}
                onChange={handleSearchInput}
                value={searchQuery}
                maxLength={selectedBubble === "phone" ? 10 : 50}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && searchQuery.trim()) {
                    handleSearchIconClick();
                  }
                }}
              />
              <div
                onClick={handleSearchIconClick}
                style={{
                  cursor: searchQuery.trim() ? "pointer" : "not-allowed",
                  opacity: searchQuery.trim() ? 1 : 0.6,
                }}
              >
                <SearchIcon />
              </div>
            </div>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                marginTop: "4px",
                minHeight: "16px",
              }}
            >
              {getErrorMessage()}
            </div>
          </div>
        )}

        {/* Data Grid with updated columns */}
        <DataGrid
          rows={filteredAppointments}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[20, 50, 100]}
          getRowId={(row) => row.id || row.orderNumber}
          onRowClick={handleRowClick}
          rowSelection={false}
          rowCount={
            hasMore
              ? (paginationModel.page + 2) * paginationModel.pageSize
              : filteredAppointments.length
          }
          paginationMode="server"
        />
        <OrderModal
          open={isModalOpen}
          onClose={closeModal}
          fetchOrder={fetchOrder}
          startDate={startDate}
          EndDate={endDate}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
};

export default OrderTab;
