import { Navigate, Outlet, Route } from "react-router-dom";

import Header from "../components/register-and-login/Header";
import AppRoutesPage from "../pages/common/AppRoutesPage";

import registerAndLoginPageBgImg from "../assets/images/background-images/provider-portal-bkg.jpeg";
import { SidebarDisplayProvider } from "../context/sidebar-display";

const getRoutes = (path, routes, shouldAllow, navigatePath, currentPath) => {
  const Page =
    path === "user" ? (
      <div
        className="min-h-screen flex flex-col bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${registerAndLoginPageBgImg})`,
          minHeight: "100vh",
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderImage:
            "linear-gradient(rgba(0, 0, 0, 0.067),rgb(85, 145, 139)) 0 fill / 1 / 0 stretch",
        }}
      >
        <Header page={currentPath} /> {/* Header of Sign Up and Login pages. */}
        <Outlet />
      </div>
    ) : (
      <SidebarDisplayProvider>
        <AppRoutesPage>
          <Outlet />
        </AppRoutesPage>
      </SidebarDisplayProvider>
    );

  return (
    <Route path={`/${path}`} element={Page}>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            shouldAllow ? route.component : <Navigate to={`/${navigatePath}`} />
          }
        />
      ))}

      <Route index element={<Navigate to="/app/home" />} />

      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  );
};

export default getRoutes;
