import { useContext, useEffect, useState } from "react";
import ModeContext from "../../context/mode-context";
import Button from "../ui/Button";
import { rxOneApi } from "../../utils/api/api";
import { RX_ONE_ENDPOINTS } from "../../utils/api/apiEndPoints";
import Toast from "../ui/Toast";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { styled } from "@mui/material";
import RoleModal from "./RoleModal";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  tableCellClasses,
  Checkbox,
  Input,
  Modal,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const Roles = () => {
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const { mode } = useContext(ModeContext);
  const [openmodal, setOpenmodal] = useState(false);
  const [roless, setRoless] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAnyCheckboxSelected = Object.values(selectedRows).some(
    (value) => value === true
  );
  const [clinicalLoader, setClinicalLoader] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleclose = () => {
    setIsModalOpen(false);
  };

  const handleRoleAdded = (newRole) => {
    setSelectedRole(newRole);
    fetchRoleDetails(newRole.value);
  };

  const fetchNotifications = async () => {
    setClinicalLoader(true);
    const userToken = localStorage.getItem("usr_token");
    rxOneApi.setUserSecretAuthHeaders();
    try {
      const res = await rxOneApi.get(
        RX_ONE_ENDPOINTS.SETTINGS.FETCH_ROLES + "/" + mode + "/" + userToken
      );
      if (res && res.data.all_roles?.length > 0) {
        setClinicalLoader(false);
        setRoles(res.data.all_roles);
        // Only set the first role if there isn't already a selected role
        if (!selectedRole) {
          const firstRole = {
            label: res.data.all_roles[0].role_name,
            value: res.data.all_roles[0].role_id,
          };
          setSelectedRole(firstRole);
          fetchRoleDetails(firstRole.value);
        }
      }
    } catch (error) {
      setClinicalLoader(false);
      setShowToast(true);
      setToastType("error");
      setToastMessage("Invalid session. Please login again.");
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchRoleDetails = async (role) => {
    setClinicalLoader(true);
    const userToken = localStorage.getItem("usr_token");
    rxOneApi.setUserSecretAuthHeaders();
    try {
      const res = await rxOneApi.get(
        `${RX_ONE_ENDPOINTS.SETTINGS.FETCH_ROLE_DETAILS}/${mode}/${userToken}/${role}`
      );
      if (res) {
        setClinicalLoader(false);
        setRoless(res?.data?.dashboard_privileges_list);
        // Initialize selectedRows based on is_selected values
        const initialSelection = {};
        res?.data?.dashboard_privileges_list.forEach((item, index) => {
          initialSelection[item.code] = item.is_selected;
        });
        setSelectedRows(initialSelection);
      }
    } catch (error) {
      setClinicalLoader(false);
      setShowToast(true);
      setToastType("error");
      setToastMessage("Error fetching role details");
    }
  };

  // Function to handle role deletion
  const handleRemoveRole = (e, roleId, roleName) => {
    e.stopPropagation(); // Prevent the dropdown from opening/closing
    
    // Find the role in the original roles array
    const roleToRemove = roles.find(role => role.role_id === roleId);
    
    // Only proceed if delete is allowed
    if (roleToRemove && roleToRemove.delete_allowed) {
      setRoleToDelete({ id: roleId, name: roleName });
      setShowDeleteConfirm(true);
    }
  };
  

  const confirmDelete = () => {
    if (roleToDelete) {
      remove(roleToDelete.id);
    }
    setShowDeleteConfirm(false);
    setRoleToDelete(null);
  };
  
  // Create a function to cancel deletion
  const cancelDelete = () => {
    setShowDeleteConfirm(false);
    setRoleToDelete(null);
  };

  // Modified remove function to properly refresh after deletion
  const remove = async (id) => {
    setClinicalLoader(true);
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");
      rxOneApi.setUserSecretAuthHeaders();
      const res = await rxOneApi.delete(
        RX_ONE_ENDPOINTS.SETTINGS.REMOVE_ROLE +
          "/" +
          mode +
          "/" +
          userToken +
          "/" +
          id
      );

      if (res) {
        setClinicalLoader(false);
        setShowToast(true);
        setToastType("success");
        setToastMessage("Role removed successfully");

        // If the deleted role was the currently selected one, clear the selection
        if (selectedRole && selectedRole.value === id) {
          setSelectedRole(null);
          setRoless([]);
        }

        // Refresh the roles list
        fetchNotifications();
      }
    } catch (err) {
      setClinicalLoader(false);
      setShowToast(true);
      setToastType("error");
      // Extract and display the actual error message from the API response
      const errorMessage = err.response?.data?.message || "Error removing role";
      setToastMessage(errorMessage);
    }
  };

  // Custom components for react-select
  const Option = ({ innerProps, data, isFocused, innerRef }) => {
    // Special rendering for "Add New +" option
    if (data.value === "add_new") {
      return (
        <div
          {...innerProps}
          ref={innerRef}
          style={{
            padding: '8px 12px',
            backgroundColor: isFocused ? '#f0f0f0' : 'white',
            cursor: 'pointer',
          }}
        >
          <div className="text-blue-600">Add New +</div>
        </div>
      );
    }
    
    return (
      <div
        {...innerProps}
        ref={innerRef}
        style={{
          padding: '8px 12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isFocused ? '#f0f0f0' : 'white',
          cursor: 'pointer'
        }}
      >
        <div>{data.label}</div> {/* Change to use data.label instead of data.roleName */}
        {data.deleteAllowed ? (
          <div
            onClick={(e) => handleRemoveRole(e, data.value, data.label)}
            style={{ color: 'red', cursor: 'pointer' }}
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </div>
        ) : (
          <div
            style={{ color: '#ccc', cursor: 'not-allowed' }}
            title="This role cannot be deleted"
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </div>
        )}
      </div>
    );
  };
  // Create role options with additional properties
  const roleOptions = [
    ...roles.map((role) => ({
      label: role.role_name,  // This is used by react-select for display
      value: role.role_id,    // This is the value stored when selected
      roleName: role.role_name, // This is for our custom component
      deleteAllowed: role.delete_allowed,
    })),
    {
      label: "Add New +",
      value: "add_new",
    },
  ];

  const handleRoleChange = (selectedOption) => {
    if (selectedOption?.value === "add_new") {
      setNewRoleName("");
      setIsModalOpen(true);
    } else {
      setSelectedRole(selectedOption);
      fetchRoleDetails(selectedOption.value);
    }
  };

  const handleCheckboxChange = (code) => {
    setSelectedRows((prev) => ({
      ...prev,
      [code]: !prev[code],
    }));
  };

  const handlesub = async () => {
    setClinicalLoader(true);
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");
      const role = selectedRole?.value;

      if (!role) {
        setShowToast(true);
        setToastType("error");
        setToastMessage("Please select a role first");
        return;
      }

      // Filter and create arrays from selected rows
      const selectedItems = roless.filter((row) => selectedRows[row.code]);
      const requestBody = {
        menu_list: selectedItems.map((item) => item.menu), // Getting features
        privileges_list: selectedItems.map((item) => item.code), // Getting codes
      };

      const res = await rxOneApi.post(
        `${RX_ONE_ENDPOINTS.SETTINGS.ROLE_SUBMITION}/${mode}/${userToken}/${role}`,
        requestBody,
        {
          headers: {
            usr_secret: userSecret,
            "Content-Type": "application/json",
          },
        }
      );

      if (res) {
        setClinicalLoader(false);
        setShowToast(true);
        setToastType("success");
        setToastMessage("Role privileges updated successfully");
        // Optionally refresh the data
        fetchRoleDetails(role);
      }
    } catch (err) {
      setClinicalLoader(false);
      setShowToast(true);
      setToastType("error");
      setToastMessage("Error updating role privileges");
    }
  };

  // Format the display for create-new input text
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;

  return (
    <>
      {clinicalLoader && (
        <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.6",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={clinicalLoader}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      )}

      <div className="relative">
        <CreatableSelect
          placeholder="Select Role"
          value={selectedRole}
          options={roleOptions}
          onChange={handleRoleChange}
          onCreateOption={(inputValue) => {
            setNewRoleName(inputValue);
            setIsModalOpen(true);
          }}
          formatCreateLabel={formatCreateLabel}
          components={{
            Option,
          }}
          styles={{
            control: (controlStyles) => ({
              ...controlStyles,
              padding: "0px",
              border: "2px solid #b3c6e7",
              borderRadius: "0",
            }),
            container: (containerStyles) => ({
              ...containerStyles,
              width: "200px",
              marginLeft: "1.5rem",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 9999,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: "black",
              visibility: "visible",
              padding: "0",
              margin: "0",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              margin: "0 5px",
              maxWidth: "calc(100% - 10px)",
            }),
            option: () => ({}),
          }}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          isClearable={false}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <p className="mx-3 mb-0 text-secondary">
            Click 'Save' after selecting/de-selecting checkboxes to save the
            changes.
          </p>
          <Button
            style={{ textAlign: "center" }}
            onClick={handlesub}
            disabled={!selectedRole || !isAnyCheckboxSelected}
          >
            SAVE
          </Button>
        </div>
        <RoleModal
          isOpen={isModalOpen}
          onClose={handleclose}
          fetchNotifications={fetchNotifications}
          onRoleAdded={handleRoleAdded}
          initialRoleName={newRoleName}
        />
        <TableContainer
          component={Paper}
          style={{ marginBottom: ".5rem", marginTop: "1rem" }}
        >
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            <TableHead>
              <TableRow style={{ background: "#05888a" }}>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Menu
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Privileges
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Allow
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roless &&
                Array.isArray(roless) &&
                roless.map((row) => (
                  <StyledTableRow key={row.code}>
                    <StyledTableCell>{row.menu}</StyledTableCell>
                    <StyledTableCell>{row.privileges}</StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      <Checkbox
                        checked={selectedRows[row.code] || false}
                        onChange={() => handleCheckboxChange(row.code)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showToast && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />
      )}
      {/* Add this at the end of your component return statement */}
{showDeleteConfirm && (
  <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" 
       style={{ zIndex: 1050, backgroundColor: "rgba(0,0,0,0.5)" }}>
    <div className="bg-white rounded shadow" style={{ minWidth: "350px", maxWidth: "450px" }}>
      <div className="text-white p-3" style={{backgroundColor: "red"}}>
        <div className="d-flex justify-content-between align-items-center">
          <strong>Confirm Role Deletion</strong>
          <button 
            type="button" 
            className="btn-close"
            onClick={cancelDelete}
          ></button>
        </div>
      </div>
      <div className="p-4">
        <div className="mb-3">
          <strong>Selected Role: </strong>
          <span className="badge bg-info text-dark">{roleToDelete?.name}</span>
        </div>
        
        <p>Are you sure you want to delete this role? This action cannot be undone.</p>
        
        <div className="d-flex justify-content-end mt-4">
          <button
            className="btn btn-secondary me-2"
            onClick={cancelDelete}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={confirmDelete}
            disabled={clinicalLoader}
            style={{backgroundColor: "red"}}
          >
            {clinicalLoader ? "Processing..." : "Delete"}
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default Roles;
